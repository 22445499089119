import { getTokenResponse } from "../auth";
import { AuthProvider, AuthProviderCallback, Client } from "@microsoft/microsoft-graph-client";

const authProvider: AuthProvider = async (callback: AuthProviderCallback) => {
  try {
    const tokenResponse = await getTokenResponse();
    callback(null, tokenResponse.accessToken);
  } catch (error) {
    // Pass any errors to the callback
    callback(error, null);
  }
};

const options = {
  authProvider,
};

const client = Client.init(options);

export const getAllUsersList = async (orgId: string) => {
  try {
    // attributes to retrieve for each user
    const selectAttributes = ["id", "displayName", "mail"];
    const selectQuery = selectAttributes.join(",");

    // Make the API call to retrieve the list of users with selected attributes
    const users = await client
      .api(`groups/${orgId}/transitiveMembers/microsoft.graph.user?$count=true`)
      .header("ConsistencyLevel", "eventual")
      .select(selectQuery)
      .get();

    return users;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error("Error fetching all users list:", error);
  }
};

export const getUser = async (orgId: string, userName: string) => {
  try {
    // attributes to retrieve for each user
    const selectAttributes = ["id", "displayName", "mail"];
    const selectQuery = selectAttributes.join(",");

    // Make the API call to retrieve the list of users with selected attributes
    const user = await client
      .api(`groups/${orgId}/transitiveMembers/microsoft.graph.user?$count=true`)
      .header("ConsistencyLevel", "eventual")
      .select(selectQuery)
      .search(`"displayName:${userName}"`)
      .get();

    return user;
  } catch (error) {
    // Handle any errors that occur during the API call
    console.error("Error fetching user:", error);
  }
};
