import { Box } from "@mui/material";
import { GridRowHeightParams } from "@mui/x-data-grid";
import { useCallback, useEffect, useState } from "react";
import { Units, ReportSessionItemStyles } from "../../types";
import { convertTableValues } from "../../utils/auxiliary";
import { StyledDataGrid } from "./styles";
import { getFRColumns } from "./table.config";
import { NotTableData } from "../NotTableData";
import { Loader } from "../Loader";
import { getFinancialReportingByCompanyId } from "../../services/financial_reporting";
import { Columns, Rows } from "../../services/financial_reporting/types";

interface ReportedRowHeight extends GridRowHeightParams {
  style?: string;
}

export type FinancialReportingTableProps = {
  companyId: number;
  unit: Units;
  currency: string;
  enableConversions?: boolean;
};

export const FinancialReportingTable = ({ companyId, unit, currency, enableConversions }: FinancialReportingTableProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [frRows, setFrRows] = useState<Rows[]>([]);
  const [columnData, setColumnData] = useState<Columns>();

  const applyConversions = useCallback(
    async (rowData: any[]) => {
      if (!rowData || rowData.length === 0) return [];

      const convertedData: any[] = [];

      for (const d of rowData) {
        const convertedValues: Record<string, number | string> = {};

        // Iterate over the properties of the rows
        for (const prop in d) {
          if (typeof d[prop] === "number" && prop !== "id") {
            // Convert only numeric properties excluding 'id'
            convertedValues[prop] = await convertTableValues(d[prop], unit, enableConversions, d.style);
          } else {
            convertedValues[prop] = d[prop];
          }
        }
        convertedData.push(convertedValues);
      }
      return convertedData;
    },
    [enableConversions, unit]
  );

  useEffect(() => {
    setLoading(true);

    getFinancialReportingByCompanyId(companyId).then((data) => {
      const rowData = data?.rows ? data.rows : [];
      applyConversions(rowData).then((dataAfterConversion) => {
        setFrRows(dataAfterConversion);
      });
      setColumnData(data.columns as Columns);
      setLoading(false);
    });
  }, [companyId, applyConversions]);

  if (loading) return <Loader />;

  return (
    <>
      {!frRows.length ? (
        <NotTableData
          title="Sorry, there's no data to display here at the moment."
          content="We don't seem to have any data available for this report."
        />
      ) : (
        <div style={{ display: "flex", height: "100%" }}>
          <Box sx={{ flexGrow: 1 }}>
            <StyledDataGrid
              rows={frRows}
              columns={getFRColumns(columnData)}
              autoHeight
              headerHeight={32}
              disableColumnMenu={true}
              disableColumnFilter
              sortingMode="server"
              hideFooter={true}
              getRowClassName={(params) => `row-class--${params.row.style}`}
              getRowHeight={(values: ReportedRowHeight) => {
                const datapointName = columnData ? columnData.currencyUnit : "";
                const rowStyle = values.model.style;
                const rowName = values.model[datapointName];

                if (
                  rowStyle === ReportSessionItemStyles.REPORTED_TITLE ||
                  rowStyle === ReportSessionItemStyles.STANDARD_TITLE ||
                  rowStyle === ReportSessionItemStyles.PERIOD_TITLE
                ) {
                  return 36;
                } else if (rowName !== undefined && rowName.length < 44) {
                  return 32;
                } else {
                  return "auto";
                }
              }}
            />
          </Box>
        </div>
      )}
    </>
  );
};
