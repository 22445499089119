import { Box, Grid, Snackbar, Stack, Typography, styled } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";

export const Container = styled(Grid)`
  min-height: calc(100vh - 64px);
  background-color: #fafafa;
  border-radius: 0px;
  width: 100%;
  margin-left: 78px;
`;

export const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const HeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSticky",
})<{ isSticky?: boolean }>(({ theme, isSticky }) => ({
  ...(isSticky && { position: "sticky", top: "64px" }),
  padding: "2.5rem 2rem",
  borderBottom: "1px solid #C3C9CE",
  backgroundColor: theme.palette.common.white,
  zIndex: 100,
  "& .MuiBox-root": {
    "& .MuiTypography-h4": { fontSize: "1.75rem", lineHeight: "2.5rem" },
  },
}));

export const ButtonStack = styled(Stack)`
  .MuiButtonBase-root.MuiButton-root {
    color: #1a50dd;
    :first-of-type {
      margin-right: 0.5rem;
    }
  }
  .MuiButton-root.MuiButton-contained {
    color: #fff;
    background-color: #1a50dd;
  }
`;

export const FormStyled = styled("form")({
  maxWidth: "100%",
});
export const UploadContainer = styled(Box)({
  maxWidth: "680px",
  margin: "2.25rem auto 2.25rem",
  padding: "0.5rem 2.5rem",
  backgroundColor: "#fff",
  borderRadius: "6px",
  boxShadow: "0px 1px 10px 1px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.10)",
});

export const TitleStyled = styled(Typography)(({ theme }) => ({
  paddingTop: "2.5rem",
  textAlign: "center",
  marginBottom: theme.spacing(4),
  fontSize: "1.375rem",
  lineHeight: "1.27",
  "&.MuiTypography-root.MuiTypography-h5": { fontSize: "1rem" },
}));

export const IconBox = styled(Box)`
  padding-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  align-self: center;
  height: 5rem;
`;

export const DescriptionStyled = styled(Typography)({
  marginBottom: "2rem",
  textAlign: "center",
  fontSize: "0.875rem",
  lineHeight: "1.33",
});

export const StyledSubtitle = styled(Typography)({
  marginBottom: "-1rem",
  fontSize: "0.875rem",
  fontWeight: 600,
  lineHeight: "1.33",
});

export const StyledDocumentItem = styled(Stack)({
  justifyContent: "space-between",
  alignItems: "center",

  "& div.MuiBox-root": {
    width: "100%",
  },
  "& div > div": {
    justifyContent: "flex-end",
  },
  "& div > div > p": {
    marginRight: "auto",
  },
  "& div > div > button": {
    marginLeft: "2rem",
  },
  "& svg.document-check-icon": {
    marginLeft: "1.5rem",
    fill: "#019886",
  },
});

export const ErrorMessage = styled(Typography)({
  color: "#9F372F",
  fontSize: "0.875rem",
  fontWeight: 500,
  marginBottom: "0.5rem",
  marginLeft: "0.375rem",
});

// DateFields
export const StyledDatePicker = styled(DesktopDatePicker)({
  width: "100%",

  "& > label": {
    marginTop: "-0.375rem",
  },
  "& > .MuiOutlinedInput-root": {
    padding: "0.375rem 0.5rem 0.375rem 0.375rem",
  },
  "& > .MuiOutlinedInput-root > .MuiOutlinedInput-input": {
    padding: "0.156rem 0.25rem 0.156rem 0.375rem",
  },
  "& > .MuiOutlinedInput-root > .MuiInputAdornment-root > .MuiButtonBase-root": {
    color: "#1a50dd",
  },
  "& .MuiPickersCalendarHeader-root": {
    display: "none",
  },
  "&:hover": {
    borderColor: "#5F5F5F",
  },
});

export const DateContainer = styled(Box)({
  "& > div": {
    padding: "0",
  },
});

type AlertColor = "success" | "info" | "warning" | "error";

export const StyledSnackbar = styled(Snackbar)<{ appearance: AlertColor }>(({ theme, appearance }) => {
  const colorStyleMap = {
    info: {
      backgroundColor: "#D9E0F5",
      borderColor: "#1A50DD",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#1A50DD",
      },
    },
    error: {
      backgroundColor: "#FCE4E3",
      borderColor: "#E72215",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#E72215",
      },
    },
    warning: {
      backgroundColor: "#FBE7C6",
      borderColor: "#E1802B",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#E1802B",
      },
    },
    success: {
      backgroundColor: "#D2E9E7",
      borderColor: "#019886",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#019886",
      },
    },
  };
  return {
    "& .MuiPaper-root.MuiAlert-root ": {
      border: 0,
      borderLeft: 3,
      borderStyle: "solid",
      color: "#292727",
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
      padding: theme.spacing(2, 1.625),
      paddingRight: theme.spacing(2.375),
      display: "flex",
      width: "100%",
      gap: theme.spacing(1),
      ...colorStyleMap[appearance],

      "& .MuiAlert-icon ": {
        padding: 0,
        marginRight: 0,
        alignItems: "center",
      },

      "& .MuiAlert-message ": {
        padding: 0,
        width: "100%",
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },

      "& .MuiAlert-message .MuiLink-root ": {
        marginTop: theme.spacing(1),
      },

      "& .MuiAlert-action ": {
        padding: 0,
      },
    },

    width: "100%",
  };
});
