import { IOption } from "@alterdomus-analytics/dna-ui";

export interface EditDealTeamProps {
  standAlone: boolean;
  orgId: string;
  companyId: string;
  dealTeam?: { user_name: string; user_id: string; is_owner: boolean }[];
  close?: () => void;
  reloadTeam?: () => void;
  dealTeamErrors?: string[]; // prop for error handling from parent form
  clearTeam?: boolean;
}

export interface IOptionDealTeam extends IOption {
  label: string;
  value: string;
  user_id: string;
  email: string;
  role?: string;
  [key: string]: {} | undefined | string;
}

export interface DealTeamMembersForm {
  selectedUsers: IOptionDealTeam[];
}

export interface DealTeamMembers {
  label: string;
  value: string;
  user_id: string;
  email: string;
  role: UserOptions;
}

export interface UserRole {
  id: string;
  name: string;
}

export enum UserOptions {
  OWNER = "0",
  MEMBER = "1",
  REMOVE = "2",
}
