import { LinearProgress, Tabs } from "@alterdomus-analytics/dna-ui";
import { Box, CardContent, CardHeader, Link, Stack, Typography } from "@mui/material";
import { DownloadSimple } from "@phosphor-icons/react";
import { Base64 } from "js-base64";
import { Plus, X } from "phosphor-react";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import noTasks from "../../assets/images/noTasks.svg";
import { AnalystComments } from "../../components/AnalystComment";
import { AppHeader } from "../../components/AppHeader";
import { StyledBreadcrumbs } from "../../components/Breadcrumbs/styles";
import { Chart, ChartType } from "../../components/Chart";
import { Commentary } from "../../components/Commentary";
import DealSummaryPane from "../../components/DealSummaryPane";
import { Editor } from "../../components/Editor";
import { config } from "../../components/Editor/editor.config";
import { FinancialReportingTable } from "../../components/FinancialReportingTable";
import { StatementTitle } from "../../components/FinancialReportingTable/styles";
import { StatementContainer } from "../../components/FinancialStatement/styles";
import { SideBarMenu } from "../../components/SideBarMenu";
import TabsStatements from "../../components/TabsStatement";
import { useAppSelector } from "../../redux/hooks";
import { setCompany } from "../../redux/reducers/companyReducer";
import { RootState } from "../../redux/store";
import { deleteAnalystById, getLatestAnalystById } from "../../services/analyst_comments";
import { CovenantsCashCoverChartData, getCovenantsCashCoverChartData } from "../../services/charts/covenants_cash_cover";
import {
  CovenantsInterestCoverChartData,
  getCovenantsInterestCoverChartData,
} from "../../services/charts/covenants_interest_cover";
import {
  CovenantsNetTotalLeverageChartData,
  getCovenantsNetTotalLeverageChartData,
} from "../../services/charts/covenants_net_total_leverage";
import { EbitdaPerformanceChartData, getEbitdaPerformanceChartData } from "../../services/charts/ebitda_performance";
import { LeverageTrendChartData, getLeverageTrendChartData } from "../../services/charts/leverage_trends";
import { RevenuePerformanceChartData, getRevenuePerformanceChartData } from "../../services/charts/revenue_performance";
import { getDealById } from "../../services/deals";
import { getFile } from "../../services/files";
import { getIncomeFilters } from "../../services/income_statement";
import { AnalystComment, Company, ReportingPeriods, StatementType, Units } from "../../types";
import { formatDate } from "../../utils/formatDate";
import { getReportingPeriodAbbreviationLegacy, getReportingPeriodByAbbreviation } from "../../utils/reporting-periods-helper";
import {
  ChartContainer,
  ChartsHeader,
  CommentCard,
  CommentLoader,
  CompanyPerformanceTitle,
  Container,
  DownloadButton,
  EmptyCommentary,
  HeaderItem,
  IconBox,
  ItemContainer,
  LoaderContainer,
  PageHeader,
  PageWrapper,
  StyledButton,
  StyledSecondaryButton,
  StyledTertiaryButton,
  StyledWrapper,
  SubItemContainer,
  TabGrid,
  TabGridItem,
  TextBox,
  Title,
  Wrapper,
} from "./styles";
import { BalanceSheet } from "./tabs/BalanceSheet";
import { CashFlow } from "./tabs/CashFlow";
import { Commentaries, CommentaryTab } from "./tabs/Commentary";
import { Covenants } from "./tabs/Covenants";
import { DebtStructure } from "./tabs/DebtStructure";
import { IncomeStatement } from "./tabs/IncomeStatement";
import { KpiStatement } from "./tabs/KPI";

export const CompanyPage = () => {
  const dispatch = useDispatch();
  const _params = useParams();
  const companyId = _params.companyId;
  const userState = useAppSelector((state) => state.app.user);
  const storeDeal = useSelector<RootState, Company>((state) => state.app.selectedCompany);
  const [deal, setDeal] = useState<Company>();
  const [orgId, setOrgId] = useState<string>("");
  const [analystComments, setAnalystComments] = useState<AnalystComment[]>([]);
  const [reportPeriod, setReportPeriod] = useState<ReportingPeriods>(ReportingPeriods.Monthly);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [hasComments, setHasComments] = useState<boolean>(true);
  const [commentAdded, setCommentAdded] = useState(false);
  const [editingComment, setEditingComment] = useState<AnalystComment | undefined>(undefined);
  const [isEditingCommentId, setIsEditingCommentId] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [commentDeleteLoader, setCommentDeleteLoader] = useState<boolean>(false);
  const [leverageTrendChartData, setLeverageTrendChartData] = useState<LeverageTrendChartData | null>(null);
  const [revenuePerformanceChartData, setRevenuePerformanceChartData] = useState<RevenuePerformanceChartData | null>(null);
  const [ebitdaPerformanceChartData, setEbitdaPerformanceChartData] = useState<EbitdaPerformanceChartData | null>(null);
  const [activeTab, setActiveTab] = useState<string | number>(0);
  const [activeCommentaryButton, setActiveCommentaryButton] = useState<Commentaries>(Commentaries.Analyst);
  const [covenantsNetTotalLeverageChartData, setCovenantsNetTotalLeverageChartData] =
    useState<CovenantsNetTotalLeverageChartData | null>(null);
  const [covenantsCashCoverChartData, setCovenantsCashCoverChartData] = useState<CovenantsCashCoverChartData | null>(null);
  const [covenantsInterestCoverChartData, setCovenantsInterestCoverChartData] = useState<CovenantsInterestCoverChartData | null>(
    null
  );
  const allowedOrgIds = [
    "b3edbd33-3fda-4766-8707-3dd51c4a7a39",
    "aac3bfd3-5443-4b74-8ab4-4eee8437f38a",
    "a0370e1c-773b-402d-a589-a7adbbc82f2c",
  ];

  const loadChartData = useCallback(async () => {
    const companyReportingPeriod = await getReportingPeriodByAbbreviation(deal?.reporting_cycle);

    //TODO we need a message or something like that here.
    if (!companyReportingPeriod) return;

    const incomeFilters = await getIncomeFilters(+companyId!, StatementType.STANDARDIZED, companyReportingPeriod);
    const chartFinancialYear = incomeFilters.financial_years[0];
    const revenuePerformanceChartdata = await getRevenuePerformanceChartData(
      +companyId!,
      companyReportingPeriod,
      chartFinancialYear,
      deal?.enable_conversions,
      deal?.reporting_unit
    );
    const ebitdaPerformanceChartData = await getEbitdaPerformanceChartData(
      +companyId!,
      companyReportingPeriod,
      chartFinancialYear,
      deal?.enable_conversions,
      deal?.reporting_unit
    );
    const leverageTrendChartData = await getLeverageTrendChartData(
      +companyId!,
      companyReportingPeriod,
      chartFinancialYear,
      deal?.enable_conversions,
      deal?.reporting_unit
    );
    const netTotalLeverageData = await getCovenantsNetTotalLeverageChartData(
      +companyId!,
      companyReportingPeriod,
      chartFinancialYear,
      deal?.enable_conversions,
      deal?.reporting_unit
    );
    const interestCoverData = await getCovenantsInterestCoverChartData(
      +companyId!,
      companyReportingPeriod,
      chartFinancialYear,
      deal?.enable_conversions,
      deal?.reporting_unit
    );
    const cashCoverChartData = await getCovenantsCashCoverChartData(
      +companyId!,
      companyReportingPeriod,
      chartFinancialYear,
      deal?.enable_conversions,
      deal?.reporting_unit
    );
    setRevenuePerformanceChartData(revenuePerformanceChartdata);
    setEbitdaPerformanceChartData(ebitdaPerformanceChartData);
    setLeverageTrendChartData(leverageTrendChartData);
    setCovenantsNetTotalLeverageChartData(netTotalLeverageData);
    setCovenantsInterestCoverChartData(interestCoverData);
    setCovenantsCashCoverChartData(cashCoverChartData);
  }, [companyId, deal?.enable_conversions, deal?.reporting_cycle, deal?.reporting_unit]);

  useEffect(() => {
    const storeVal = JSON.stringify(storeDeal);
    const componentVal = JSON.stringify(deal);
    if (storeVal !== componentVal) {
      setDeal(storeDeal);
    }
  }, [storeDeal, deal]);

  useEffect(() => {
    getDealById(+companyId!).then((data) => {
      switch (data.reporting_cycle) {
        case "m":
          setReportPeriod(ReportingPeriods.Monthly);
          break;
        case "q":
          setReportPeriod(ReportingPeriods.Quarterly);
          break;
        default:
          setReportPeriod(ReportingPeriods.Monthly);
      }
      setDeal(data as Company);
      setOrgId(data.orgId);
      dispatch(setCompany(data));
      loadChartData();
      setLoading(false);
    });
  }, [companyId, dispatch, loadChartData]);

  useEffect(() => {
    const fetchCommentData = async () => {
      try {
        const data = await getLatestAnalystById(+companyId!);
        setAnalystComments(data as AnalystComment[]);
        Array.isArray(data) && data.length > 0 ? setHasComments(true) : setHasComments(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // FIXME Handle the error appropriately e.g. show an error dialog
      } finally {
        setCommentAdded(false);
      }
    };

    if (commentAdded) {
      // Fetch the updated data when a comment is added
      fetchCommentData();
    }
    // or Fetch the initial data
    fetchCommentData();
  }, [companyId, commentAdded]);

  const handleDeleteComment = async (id: number) => {
    // Show the loader
    setCommentDeleteLoader(true);
    try {
      // Make the delete request
      const response = await deleteAnalystById(+companyId!, id);

      if (response) {
        // Fetch the updated data after a successful delete
        const data = await getLatestAnalystById(+companyId!);
        setAnalystComments(data as AnalystComment[]);
        Array.isArray(data) && data.length > 0 ? setHasComments(true) : setHasComments(false);
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    } finally {
      setCommentDeleteLoader(false);
    }
  };

  const handleCloseEditor = () => {
    setEditingComment(undefined);
    setIsEditingCommentId(null);
    setOpenEditor((prev) => !prev);
  };

  const handleEditComment = (id: number) => {
    const commentToEdit = analystComments.find((comment) => comment.id === id);
    setEditingComment(commentToEdit);
    setIsEditingCommentId(id); // Set isEditingCommentId to the ID of the comment being edited
    setOpenEditor(true);
  };

  const handleDownloadFinModel = async () => {
    const fileContent = await getFile(deal?.fin_model_uri!);

    // Create a Blob URL and initiate a download
    const url = window.URL.createObjectURL(new Blob([fileContent], { type: "application/octet-stream" }));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${deal?.company_trade_name}.xlsx`);
    document.body.appendChild(link);
    link.click();

    // Clean up the URL and remove the temporary link
    window.URL.revokeObjectURL(url);
    document.body.removeChild(link);
  };

  const handleViewCommentaryClick = () => {
    //We set the commentary tab as active
    setActiveTab(4);
    setActiveCommentaryButton(Commentaries.Management);
  };

  const handleTabsChange = (e: React.SyntheticEvent, value: string | number) => {
    setActiveTab(value);
    setActiveCommentaryButton(Commentaries.Analyst);
  };

  return (
    <>
      <AppHeader />
      <StyledWrapper>
        <SideBarMenu />
        <PageWrapper>
          <PageHeader container>
            <HeaderItem item xs={12} mb={deal?.fin_model_uri ? "-0.75rem" : "0.05rem"}>
              <Box display={"flex"} justifyContent={"space-between"} alignItems={"flex-start"}>
                <StyledBreadcrumbs
                  links={[
                    { label: "PORTFOLIO ANALYZER", href: "/analyzer" },
                    { label: `COMPANY: ${deal?.company_trade_name}`, href: "/" },
                  ]}
                ></StyledBreadcrumbs>
                {deal?.fin_model_uri && (
                  <DownloadButton
                    label="Financial Model"
                    variantType="secondary"
                    sx={{ marginRight: "0.5rem" }}
                    onClick={handleDownloadFinModel}
                    startIcon={<DownloadSimple size={16} />}
                  />
                )}
              </Box>
            </HeaderItem>

            <HeaderItem item xs={12}>
              <Title e2e-test-id={"company-name"}>{deal?.company_trade_name}</Title>
            </HeaderItem>
          </PageHeader>

          <Wrapper container>
            <Container item xs={12}>
              <Tabs
                handleChange={handleTabsChange}
                value={activeTab}
                e2e-test-id={"company-subpage-tabs"}
                sx={{ display: "flex" }}
                labels={
                  allowedOrgIds.includes(orgId)
                    ? [
                        "Overview",
                        //"Key Performance Metrics",
                        "Financial Reporting",
                        "Debt Structure & Covenants",
                        "Financial Statements",
                        "Commentary",
                      ]
                    : [
                        "Overview",
                        //"Key Performance Metrics",
                        "Debt Structure & Covenants",
                        "Financial Statements",
                        "Commentary",
                      ]
                }
                content={
                  allowedOrgIds.includes(orgId)
                    ? [
                        <TabGrid container spacing={2}>
                          {loading ? (
                            <LoaderContainer>
                              <LinearProgress variant="query" />
                            </LoaderContainer>
                          ) : (
                            <TabGridItem container spacing={2}>
                              <ItemContainer item xs={8}>
                                {/* Management Commentary Section*/}
                                <SubItemContainer>
                                  {deal?.management_commentary != null ? (
                                    <Commentary
                                      title="Management Commentary"
                                      date={
                                        deal.management_commentary?.reporting_period
                                          ? formatDate(deal.management_commentary.reporting_period)
                                          : ""
                                      }
                                      cutoff={120}
                                      handleViewCommentaryClick={() => handleViewCommentaryClick()}
                                      headerSlot={
                                        <Link
                                          underline="hover"
                                          variant="body2"
                                          onClick={handleViewCommentaryClick}
                                          sx={{ cursor: "pointer" }}
                                        >
                                          View commentary archive
                                        </Link>
                                      }
                                      modalHeaderSlot
                                      text={deal.management_commentary.text}
                                      maxWidth="75%"
                                      companyId={companyId!}
                                    />
                                  ) : (
                                    <Commentary
                                      title="Management Commentary"
                                      cutoff={120}
                                      maxWidth="75%"
                                      companyId={companyId!}
                                    />
                                  )}
                                </SubItemContainer>
                                {/* Analyst Commentary Section*/}
                                <SubItemContainer>
                                  {openEditor ? (
                                    <CommentCard variant="outlined" sx={{ maxWidth: "100%", backgroundColor: "#E8EEEE" }}>
                                      <CardHeader
                                        title={
                                          <Stack direction="row" sx={{ alignItems: "center" }}>
                                            <Typography
                                              variant="h4"
                                              component="div"
                                              mr={1.5}
                                              e2e-test-id={`title-analyst-commentary`}
                                            >
                                              {"Recent Analyst Commentary"}
                                            </Typography>
                                            <Stack direction="row" ml={"auto"} e2e-test-id={`link-view-all`}>
                                              {/* Uncomment when we have All Analyst comments tab
                                       <Link underline="hover" variant="body2" aria-disabled sx={{ cursor: "pointer" }}>
                                        View all comments
                                      </Link> */}
                                              <StyledTertiaryButton
                                                label="Cancel"
                                                variantType="tertiary"
                                                startIcon={<X size={14} color="#1a50dd" />}
                                                onClick={handleCloseEditor}
                                              />
                                            </Stack>
                                          </Stack>
                                        }
                                      />
                                      <CardContent sx={{ maxWidth: "100%" }}>
                                        <Editor
                                          companyId={companyId}
                                          config={config}
                                          options={["Q3 2022", "Q1 2022"]}
                                          user={userState.name}
                                          close={handleCloseEditor}
                                          onCommentAdded={() => setCommentAdded(true)}
                                          editingComment={editingComment}
                                        />
                                      </CardContent>

                                      {/* AnalystComments rendered under Editor when hasComments is true & openEditor true*/}
                                      {hasComments &&
                                        analystComments !== null &&
                                        analystComments.length > 0 &&
                                        analystComments.map(
                                          (comment, index) =>
                                            // Conditionally render the AnalystComments component only if it's not being edited
                                            isEditingCommentId !== comment.id && (
                                              <AnalystComments
                                                key={comment.id}
                                                index={index}
                                                cId={comment.id}
                                                title={`Comment by ${comment.user_name}`}
                                                // const decodedReportPeriod = subtitle ? Base64.decode(subtitle) : "";
                                                subtitle={`Reporting Period ${Base64.decode(comment.reporting_period)}`}
                                                date={formatDate(comment.timestamp)}
                                                text={comment.text}
                                                commentDelete={() => handleDeleteComment(comment.id)}
                                                commentEdit={() => handleEditComment(comment.id)}
                                              />
                                            )
                                        )}
                                    </CommentCard>
                                  ) : (
                                    <CommentCard
                                      variant="outlined"
                                      sx={{ maxWidth: "100%", backgroundColor: `${hasComments ? "#E8EEEE" : "#ffffff"}` }}
                                    >
                                      <CardHeader
                                        title={
                                          <Stack direction="row" sx={{ alignItems: "center" }}>
                                            <Typography
                                              variant="h4"
                                              component="div"
                                              mr={1.5}
                                              e2e-test-id={`title-analyst-commentary`}
                                            >
                                              {"Recent Analyst Commentary"}
                                            </Typography>
                                            {hasComments && (
                                              <Stack direction="row" ml={"auto"} e2e-test-id={`link-view-all`}>
                                                {/* Uncomment when we have All Analyst comments tab
                                        <Link underline="hover" variant="body2" aria-disabled sx={{ cursor: "pointer" }}>
                                          View all comments
                                        </Link> */}
                                                <StyledSecondaryButton
                                                  label="Add Comment"
                                                  variantType="secondary"
                                                  startIcon={<Plus size={16} color="#1a50dd" />}
                                                  onClick={() => setOpenEditor(true)}
                                                />
                                              </Stack>
                                            )}
                                          </Stack>
                                        }
                                      />
                                      <CardContent sx={{ maxWidth: "100%" }} e2e-test-id={`comment-area`}>
                                        {commentDeleteLoader && (
                                          <CommentLoader>
                                            <Stack>
                                              <Typography variant="caption">Deleting ...</Typography>
                                              <LinearProgress variant="query" />
                                            </Stack>
                                          </CommentLoader>
                                        )}
                                        {/* AnalystComments rendered instead of EmptyCommentary when hasComments is true */}
                                        {hasComments && analystComments !== null && analystComments.length > 0 ? (
                                          analystComments.map(
                                            (comment, index) =>
                                              // Conditionally render the AnalystComments component only if it's not being edited
                                              isEditingCommentId !== comment.id && (
                                                <AnalystComments
                                                  key={comment.id}
                                                  index={index}
                                                  cId={comment.id}
                                                  title={`Comment by ${comment.user_name}`}
                                                  subtitle={`Reporting Period ${Base64.decode(comment.reporting_period)}`}
                                                  date={formatDate(comment.timestamp)}
                                                  text={comment.text}
                                                  commentDelete={() => handleDeleteComment(comment.id)}
                                                  commentEdit={() => handleEditComment(comment.id)}
                                                />
                                              )
                                          )
                                        ) : (
                                          <EmptyCommentary>
                                            <IconBox>
                                              <img src={noTasks} alt="No Comments" />
                                            </IconBox>
                                            <TextBox>
                                              <Typography variant="h5">Add your first commentary!</Typography>
                                              <Typography variant="body1">
                                                Add the first commentary and share your analysis.
                                              </Typography>
                                              <div>
                                                <StyledButton
                                                  label="Add Comment"
                                                  variantType="primary"
                                                  startIcon={<Plus size={16} color="#ffffff" />}
                                                  onClick={() => setOpenEditor(true)}
                                                />
                                              </div>
                                            </TextBox>
                                          </EmptyCommentary>
                                        )}
                                      </CardContent>
                                    </CommentCard>
                                  )}
                                </SubItemContainer>
                                {/* Analyst Commentary END */}
                                {/* Charts Section */}
                                <ChartsHeader
                                  title={
                                    <CompanyPerformanceTitle e2e-test-id={`title-company-performance`}>
                                      {"Company Performance"}
                                    </CompanyPerformanceTitle>
                                  }
                                />
                                <SubItemContainer container columnSpacing={2}>
                                  <SubItemContainer item xs={6}>
                                    <ChartContainer>
                                      {revenuePerformanceChartData && (
                                        <Chart
                                          type={ChartType.REVENUE_PERFORMANCE}
                                          title="Revenue"
                                          subtitle={`${getReportingPeriodAbbreviationLegacy(deal?.reporting_cycle)}, ${
                                            deal?.currency
                                          } ${deal?.reporting_unit}`}
                                          xAxis={revenuePerformanceChartData?.xAxis}
                                          yAxis={revenuePerformanceChartData?.yAxis}
                                          data={revenuePerformanceChartData?.series}
                                          unit={deal?.reporting_unit}
                                          financialYear={revenuePerformanceChartData.financialYear?.toString()}
                                        />
                                      )}
                                    </ChartContainer>
                                  </SubItemContainer>
                                  <SubItemContainer item xs={6}>
                                    <ChartContainer>
                                      {ebitdaPerformanceChartData && (
                                        <Chart
                                          type={ChartType.EBITDA_PERFORMANCE}
                                          title="EBITDA"
                                          subtitle={`${getReportingPeriodAbbreviationLegacy(deal?.reporting_cycle)}, ${
                                            deal?.currency
                                          } ${deal?.reporting_unit}`}
                                          xAxis={ebitdaPerformanceChartData?.xAxis}
                                          yAxis={ebitdaPerformanceChartData?.yAxis}
                                          data={ebitdaPerformanceChartData?.series}
                                          unit={deal?.reporting_unit}
                                          financialYear={ebitdaPerformanceChartData.financialYear?.toString()}
                                        />
                                      )}
                                    </ChartContainer>
                                  </SubItemContainer>
                                </SubItemContainer>
                                <SubItemContainer>
                                  <ChartContainer>
                                    {leverageTrendChartData && (
                                      <Chart
                                        type={ChartType.LEVERAGE_TREND}
                                        title="Leverage"
                                        subtitle={`LTM, ${deal?.currency} ${deal?.reporting_unit}`}
                                        xAxis={leverageTrendChartData?.xAxis}
                                        yAxis={leverageTrendChartData?.yAxis}
                                        data={leverageTrendChartData?.series}
                                        unit={Units.MILLIONS}
                                        titleYAxis="Leverage"
                                      />
                                    )}
                                  </ChartContainer>
                                </SubItemContainer>
                                {/* Charts END */}
                              </ItemContainer>
                              <ItemContainer item xs={4}>
                                {/* Business Description Section*/}
                                <SubItemContainer>
                                  <Commentary
                                    title="Business Description"
                                    titleHeadingLevel="h5"
                                    cutoff={120}
                                    modalHeaderSlot={false}
                                    text={deal?.description}
                                    activeTab={false}
                                    companyId={companyId!}
                                  />
                                </SubItemContainer>
                                {/* Transaction Background Section */}
                                <SubItemContainer>
                                  <Commentary
                                    title="Transaction Background"
                                    titleHeadingLevel="h5"
                                    cutoff={120}
                                    modalHeaderSlot={false}
                                    text={deal?.transaction_background}
                                    activeTab={false}
                                    companyId={companyId!}
                                  />
                                </SubItemContainer>
                                {/* Key Deal Section */}
                                <SubItemContainer>
                                  {companyId && deal && (
                                    <DealSummaryPane
                                      companyId={companyId}
                                      deal={deal}
                                      reportPeriod={reportPeriod}
                                      orgId={orgId}
                                    />
                                  )}
                                </SubItemContainer>
                                {/* Key Deal Section END */}
                              </ItemContainer>
                            </TabGridItem>
                          )}
                        </TabGrid>,
                        // Overview Tab END

                        //  Key Performance Metrics
                        // <TabGrid container>
                        //   <TabGridItem item xs={12} pt={"8px"}>
                        //     <div>KPM</div>
                        //   </TabGridItem>
                        // </TabGrid>,

                        //  Financial Reporting
                        <TabGrid container spacing={2}>
                          <TabGridItem item xs={12} pt={"0.5rem"}>
                            <StatementContainer>
                              <StatementTitle>Financial Reporting</StatementTitle>
                              <FinancialReportingTable
                                companyId={parseInt(companyId!)}
                                unit={Units.MILLIONS} //Default for FR statement
                                currency={deal?.currency!}
                                enableConversions={true}
                              />
                            </StatementContainer>
                          </TabGridItem>
                        </TabGrid>,

                        //  Debt Structure & Covenants
                        <TabGrid container spacing={2}>
                          <TabGridItem item xs={12} pt={"8px"}>
                            <TabsStatements
                              e2e-test-id={"company-DCstatement-tabs"}
                              labels={["Debt Structure", "Covenants"]}
                              content={[
                                <DebtStructure
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name || ""}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <Covenants
                                  netTotalLeverageData={covenantsNetTotalLeverageChartData}
                                  cashCoverData={covenantsCashCoverChartData}
                                  interestCoverData={covenantsInterestCoverChartData}
                                  unit={deal?.reporting_unit!}
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name!}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                              ]}
                            />
                          </TabGridItem>
                        </TabGrid>,

                        // Financial Statements
                        <TabGrid container spacing={2}>
                          <TabGridItem item xs={12} pt={"8px"}>
                            <TabsStatements
                              e2e-test-id={"company-statement-tabs"}
                              labels={["Income Statement", "Balance Sheet", "Cash Flow", "KPIs"]}
                              content={[
                                <IncomeStatement
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <BalanceSheet
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  hash={""}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <CashFlow
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <KpiStatement
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                              ]}
                            />
                          </TabGridItem>
                        </TabGrid>,

                        // Commentary Tab
                        <TabGrid container spacing={2}>
                          <TabGridItem item xs={12} pt={"8px"}>
                            <CommentaryTab
                              companyId={companyId!}
                              activeButton={activeCommentaryButton}
                              handleViewCommentaryClick={() => handleViewCommentaryClick()}
                            />
                          </TabGridItem>
                        </TabGrid>,
                      ]
                    : [
                        <TabGrid container spacing={2}>
                          {loading ? (
                            <LoaderContainer>
                              <LinearProgress variant="query" />
                            </LoaderContainer>
                          ) : (
                            <TabGridItem container spacing={2}>
                              <ItemContainer item xs={8}>
                                {/* Management Commentary Section*/}
                                <SubItemContainer>
                                  {deal?.management_commentary != null ? (
                                    <Commentary
                                      title="Management Commentary"
                                      subtitle={
                                        deal.management_commentary?.reporting_period
                                          ? formatDate(deal.management_commentary?.reporting_period)
                                          : ""
                                      }
                                      date={
                                        deal.management_commentary?.timestamp
                                          ? formatDate(deal.management_commentary?.timestamp)
                                          : ""
                                      }
                                      cutoff={120}
                                      headerSlot={
                                        <Link underline="hover" variant="body2">
                                          View commentary archive
                                        </Link>
                                      }
                                      modalHeaderSlot
                                      text={deal.management_commentary.text}
                                      maxWidth="75%"
                                      activeTab={false}
                                      companyId={companyId!}
                                    />
                                  ) : (
                                    <Commentary
                                      title="Management Commentary"
                                      cutoff={120}
                                      maxWidth="75%"
                                      companyId={companyId!}
                                      activeTab={false}
                                    />
                                  )}
                                </SubItemContainer>
                                {/* Analyst Commentary Section*/}
                                <SubItemContainer>
                                  {openEditor ? (
                                    <CommentCard variant="outlined" sx={{ maxWidth: "100%", backgroundColor: "#E8EEEE" }}>
                                      <CardHeader
                                        title={
                                          <Stack direction="row" sx={{ alignItems: "center" }}>
                                            <Typography
                                              variant="h4"
                                              component="div"
                                              mr={1.5}
                                              e2e-test-id={`title-analyst-commentary`}
                                            >
                                              {"Recent Analyst Commentary"}
                                            </Typography>
                                            <Stack direction="row" ml={"auto"} e2e-test-id={`link-view-all`}>
                                              {/* Uncomment when we have All Analyst comments tab
                                       <Link underline="hover" variant="body2" aria-disabled sx={{ cursor: "pointer" }}>
                                        View all comments
                                      </Link> */}
                                              <StyledTertiaryButton
                                                label="Cancel"
                                                variantType="tertiary"
                                                startIcon={<X size={14} color="#1a50dd" />}
                                                onClick={handleCloseEditor}
                                              />
                                            </Stack>
                                          </Stack>
                                        }
                                      />
                                      <CardContent sx={{ maxWidth: "100%" }}>
                                        <Editor
                                          companyId={companyId}
                                          config={config}
                                          options={["Q3 2022", "Q1 2022"]}
                                          user={userState.name}
                                          close={handleCloseEditor}
                                          onCommentAdded={() => setCommentAdded(true)}
                                          editingComment={editingComment}
                                        />
                                      </CardContent>

                                      {/* AnalystComments rendered under Editor when hasComments is true & openEditor true*/}
                                      {hasComments &&
                                        analystComments !== null &&
                                        analystComments.length > 0 &&
                                        analystComments.map(
                                          (comment, index) =>
                                            // Conditionally render the AnalystComments component only if it's not being edited
                                            isEditingCommentId !== comment.id && (
                                              <AnalystComments
                                                key={comment.id}
                                                index={index}
                                                cId={comment.id}
                                                title={`Comment by ${comment.user_name}`}
                                                // const decodedReportPeriod = subtitle ? Base64.decode(subtitle) : "";
                                                subtitle={`Reporting Period ${Base64.decode(comment.reporting_period)}`}
                                                date={formatDate(comment.timestamp)}
                                                text={comment.text}
                                                commentDelete={() => handleDeleteComment(comment.id)}
                                                commentEdit={() => handleEditComment(comment.id)}
                                              />
                                            )
                                        )}
                                    </CommentCard>
                                  ) : (
                                    <CommentCard
                                      variant="outlined"
                                      sx={{ maxWidth: "100%", backgroundColor: `${hasComments ? "#E8EEEE" : "#ffffff"}` }}
                                    >
                                      <CardHeader
                                        title={
                                          <Stack direction="row" sx={{ alignItems: "center" }}>
                                            <Typography
                                              variant="h4"
                                              component="div"
                                              mr={1.5}
                                              e2e-test-id={`title-analyst-commentary`}
                                            >
                                              {"Recent Analyst Commentary"}
                                            </Typography>
                                            {hasComments && (
                                              <Stack direction="row" ml={"auto"} e2e-test-id={`link-view-all`}>
                                                {/* Uncomment when we have All Analyst comments tab
                                        <Link underline="hover" variant="body2" aria-disabled sx={{ cursor: "pointer" }}>
                                          View all comments
                                        </Link> */}
                                                <StyledSecondaryButton
                                                  label="Add Comment"
                                                  variantType="secondary"
                                                  startIcon={<Plus size={16} color="#1a50dd" />}
                                                  onClick={() => setOpenEditor(true)}
                                                />
                                              </Stack>
                                            )}
                                          </Stack>
                                        }
                                      />
                                      <CardContent sx={{ maxWidth: "100%" }} e2e-test-id={`comment-area`}>
                                        {commentDeleteLoader && (
                                          <CommentLoader>
                                            <Stack>
                                              <Typography variant="caption">Deleting ...</Typography>
                                              <LinearProgress variant="query" />
                                            </Stack>
                                          </CommentLoader>
                                        )}
                                        {/* AnalystComments rendered instead of EmptyCommentary when hasComments is true */}
                                        {hasComments && analystComments !== null && analystComments.length > 0 ? (
                                          analystComments.map(
                                            (comment, index) =>
                                              // Conditionally render the AnalystComments component only if it's not being edited
                                              isEditingCommentId !== comment.id && (
                                                <AnalystComments
                                                  key={comment.id}
                                                  index={index}
                                                  cId={comment.id}
                                                  title={`Comment by ${comment.user_name}`}
                                                  subtitle={`Reporting Period ${Base64.decode(comment.reporting_period)}`}
                                                  date={formatDate(comment.timestamp)}
                                                  text={comment.text}
                                                  commentDelete={() => handleDeleteComment(comment.id)}
                                                  commentEdit={() => handleEditComment(comment.id)}
                                                />
                                              )
                                          )
                                        ) : (
                                          <EmptyCommentary>
                                            <IconBox>
                                              <img src={noTasks} alt="No Comments" />
                                            </IconBox>
                                            <TextBox>
                                              <Typography variant="h5">Add your first commentary!</Typography>
                                              <Typography variant="body1">
                                                Add the first commentary and share your analysis.
                                              </Typography>
                                              <div>
                                                <StyledButton
                                                  label="Add Comment"
                                                  variantType="primary"
                                                  startIcon={<Plus size={16} color="#ffffff" />}
                                                  onClick={() => setOpenEditor(true)}
                                                />
                                              </div>
                                            </TextBox>
                                          </EmptyCommentary>
                                        )}
                                      </CardContent>
                                    </CommentCard>
                                  )}
                                </SubItemContainer>
                                {/* Analyst Commentary END */}
                                {/* Charts Section */}
                                <ChartsHeader
                                  title={
                                    <CompanyPerformanceTitle e2e-test-id={`title-company-performance`}>
                                      {"Company Performance"}
                                    </CompanyPerformanceTitle>
                                  }
                                />
                                <SubItemContainer container columnSpacing={2}>
                                  <SubItemContainer item xs={6}>
                                    <ChartContainer>
                                      {revenuePerformanceChartData && (
                                        <Chart
                                          type={ChartType.REVENUE_PERFORMANCE}
                                          title="Revenue"
                                          subtitle={`${getReportingPeriodAbbreviationLegacy(deal?.reporting_cycle)}, ${
                                            deal?.currency
                                          } ${deal?.reporting_unit}`}
                                          xAxis={revenuePerformanceChartData?.xAxis}
                                          yAxis={revenuePerformanceChartData?.yAxis}
                                          data={revenuePerformanceChartData?.series}
                                          unit={deal?.reporting_unit}
                                          financialYear={revenuePerformanceChartData.financialYear?.toString()}
                                        />
                                      )}
                                    </ChartContainer>
                                  </SubItemContainer>
                                  <SubItemContainer item xs={6}>
                                    <ChartContainer>
                                      {ebitdaPerformanceChartData && (
                                        <Chart
                                          type={ChartType.EBITDA_PERFORMANCE}
                                          title="EBITDA"
                                          subtitle={`${getReportingPeriodAbbreviationLegacy(deal?.reporting_cycle)}, ${
                                            deal?.currency
                                          } ${deal?.reporting_unit}`}
                                          xAxis={ebitdaPerformanceChartData?.xAxis}
                                          yAxis={ebitdaPerformanceChartData?.yAxis}
                                          data={ebitdaPerformanceChartData?.series}
                                          unit={deal?.reporting_unit}
                                          financialYear={ebitdaPerformanceChartData.financialYear?.toString()}
                                        />
                                      )}
                                    </ChartContainer>
                                  </SubItemContainer>
                                </SubItemContainer>
                                <SubItemContainer>
                                  <ChartContainer>
                                    {leverageTrendChartData && (
                                      <Chart
                                        type={ChartType.LEVERAGE_TREND}
                                        title="Leverage"
                                        subtitle={`LTM, ${deal?.currency} ${deal?.reporting_unit}`}
                                        xAxis={leverageTrendChartData?.xAxis}
                                        yAxis={leverageTrendChartData?.yAxis}
                                        data={leverageTrendChartData?.series}
                                        unit={Units.MILLIONS}
                                        titleYAxis="Leverage"
                                      />
                                    )}
                                  </ChartContainer>
                                </SubItemContainer>
                                {/* Charts END */}
                              </ItemContainer>
                              <ItemContainer item xs={4}>
                                {/* Business Description Section*/}
                                <SubItemContainer>
                                  <Commentary
                                    title="Business Description"
                                    titleHeadingLevel="h5"
                                    cutoff={120}
                                    modalHeaderSlot={false}
                                    text={deal?.description}
                                    activeTab={false}
                                    companyId={companyId!}
                                  />
                                </SubItemContainer>
                                {/* Transaction Background Section */}
                                <SubItemContainer>
                                  <Commentary
                                    title="Transaction Background"
                                    titleHeadingLevel="h5"
                                    cutoff={120}
                                    modalHeaderSlot={false}
                                    text={deal?.transaction_background}
                                    activeTab={false}
                                    companyId={companyId!}
                                  />
                                </SubItemContainer>
                                {/* Key Deal Section */}
                                <SubItemContainer>
                                  {companyId && deal && (
                                    <DealSummaryPane companyId={companyId} deal={deal} reportPeriod={reportPeriod} />
                                  )}
                                </SubItemContainer>
                                {/* Key Deal Section END */}
                              </ItemContainer>
                            </TabGridItem>
                          )}
                        </TabGrid>,
                        // Overview Tab END

                        //  Key Performance Metrics
                        // <TabGrid container>
                        //   <TabGridItem item xs={12} pt={"8px"}>
                        //     <div>KPM</div>
                        //   </TabGridItem>
                        // </TabGrid>,

                        //  Debt Structure & Covenants
                        <TabGrid container spacing={2}>
                          <TabGridItem item xs={12} pt={"8px"}>
                            <TabsStatements
                              e2e-test-id={"company-DCstatement-tabs"}
                              labels={["Debt Structure", "Covenants"]}
                              content={[
                                <DebtStructure
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name || ""}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <Covenants
                                  netTotalLeverageData={covenantsNetTotalLeverageChartData}
                                  cashCoverData={covenantsCashCoverChartData}
                                  interestCoverData={covenantsInterestCoverChartData}
                                  unit={deal?.reporting_unit!}
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name || ""}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                              ]}
                            />
                          </TabGridItem>
                        </TabGrid>,

                        // Financial Statements
                        <TabGrid container spacing={2}>
                          <TabGridItem item xs={12} pt={"8px"}>
                            <TabsStatements
                              e2e-test-id={"company-statement-tabs"}
                              labels={["Income Statement", "Balance Sheet", "Cash Flow", "KPIs"]}
                              content={[
                                <IncomeStatement
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <BalanceSheet
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  hash={""}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <CashFlow
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                                <KpiStatement
                                  companyId={parseInt(companyId!)}
                                  companyName={deal?.deal_name}
                                  periodType={reportPeriod}
                                  currency={deal?.currency!}
                                  enableConversions={deal?.enable_conversions}
                                />,
                              ]}
                            />
                          </TabGridItem>
                        </TabGrid>,

                        // Commentary Tab
                        <TabGrid container spacing={2}>
                          <TabGridItem item xs={12} pt={"8px"}>
                            <CommentaryTab
                              companyId={companyId!}
                              activeButton={Commentaries.Analyst}
                              handleViewCommentaryClick={() => handleViewCommentaryClick()}
                            />
                          </TabGridItem>
                        </TabGrid>,
                      ]
                }
              />
            </Container>
          </Wrapper>
        </PageWrapper>
      </StyledWrapper>
    </>
  );
};
