import { ReportingPeriods } from "../types";

export const getMonthNameAbbreviated = async (monthNumber: number) => {
  switch (monthNumber) {
    case 1:
      return "Jan";
    case 2:
      return "Feb";
    case 3:
      return "Mar";
    case 4:
      return "Apr";
    case 5:
      return "May";
    case 6:
      return "Jun";
    case 7:
      return "Jul";
    case 8:
      return "Aug";
    case 9:
      return "Sep";
    case 10:
      return "Oct";
    case 11:
      return "Nov";
    case 12:
      return "Dec";
    default:
      throw new Error("Invalid month");
  }
};

export const getNumberOfColumns = (selectedPeriod: ReportingPeriods, reportingCycle: ReportingPeriods) => {
  switch (selectedPeriod) {
    case ReportingPeriods.Monthly:
      return 12;
    case ReportingPeriods.Quarterly:
      return 4;
    case ReportingPeriods.YTD:
      if (reportingCycle === ReportingPeriods.Monthly) return 12;
      else return 4;
    case ReportingPeriods.LTM:
      if (reportingCycle === ReportingPeriods.Monthly) return 12;
      else return 4;
  }
};

export const getPeriodHeaderName = (
  monthNumber: number,
  selectedPeriod: ReportingPeriods,
  reportingCycle: ReportingPeriods
): string => {
  if (
    selectedPeriod === ReportingPeriods.Monthly ||
    (selectedPeriod === ReportingPeriods.YTD && reportingCycle === ReportingPeriods.Monthly) ||
    (selectedPeriod === ReportingPeriods.LTM && reportingCycle === ReportingPeriods.Monthly)
  ) {
    switch (monthNumber) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return "Invalid month";
    }
  } else if (
    selectedPeriod === ReportingPeriods.Quarterly ||
    (selectedPeriod === ReportingPeriods.YTD && reportingCycle === ReportingPeriods.Quarterly) ||
    (selectedPeriod === ReportingPeriods.LTM && reportingCycle === ReportingPeriods.Quarterly)
  ) {
    switch (Math.ceil(monthNumber / 3)) {
      case 1:
        return "Q1";
      case 2:
        return "Q2";
      case 3:
        return "Q3";
      case 4:
        return "Q4";
      default:
        return "Invalid month";
    }
  } else {
    return "Invalid month";
  }
};

/**
 * Function to normalize reported months set.
 * @param {Set<number>} reportedMonthsSet - Set of reported months (1 to 12).
 * @returns {number[]} Normalized array of reported months (length 12).
 */
export const getNormalizedReportedMonthsSet = async (reportedMonthsSet: Set<number>, endPeriod?: number): Promise<number[]> => {
  let monthsArray: number[] = Array.from(reportedMonthsSet);
  monthsArray.sort((a, b) => a - b);
  // Find the starting month
  const startingPeriod = (endPeriod: number | undefined) => {
    if (endPeriod === undefined) {
      return monthsArray[0]; // Return the first month from API data if endPeriod is undefined
    } else if (endPeriod !== 12) {
      return endPeriod + 1; // Return next month if endPeriod is not December
    } else {
      return 1; // Return January if endPeriod is December
    }
  };
  let startingMonth = startingPeriod(endPeriod);
  let normalizedArray: number[] = [];
  // Push months starting from the startingMonth up to 12
  for (let i = startingMonth; i <= 12; i++) {
    normalizedArray.push(i);
  }
  // Push remaining months from 1 up to startingMonth
  for (let i = 1; i < startingMonth; i++) {
    normalizedArray.push(i);
  }

  return normalizedArray;
};

/**
 * Function to normalize reported quarters set.
 * @param {Set<number>} reportedQuartersSet - Set of reported quarters (1 to 4).
 * @returns {number[]} Normalized array of reported quarters (length 4).
 */
export const getNormalizedReportedQuartersSet = async (
  reportedQuartersSet: Set<number>,
  endPeriod?: number
): Promise<number[]> => {
  let quartersArray: number[] = Array.from(reportedQuartersSet);
  quartersArray.sort((a, b) => a - b);
  // Find the starting quarter
  const startingPeriod = (endPeriod: number | undefined) => {
    const Q4 = [10, 11, 12];
    if (endPeriod === undefined) {
      return quartersArray[0]; // Return the first quarter from API data if endPeriod is undefined
    } else {
      if (!Q4.includes(endPeriod)) {
        return Math.ceil((endPeriod + 1) / 3);
      } else {
        return 1;
      }
    }
  };
  let startingQuarter = startingPeriod(endPeriod);
  let normalizedArray: number[] = [];
  // Push quarters starting from the startingQuarter up to 4
  for (let i = startingQuarter; i <= 4; i++) {
    normalizedArray.push(i);
  }
  // Push remaining quarters from 1 up to startingQuarter
  for (let i = 1; i < startingQuarter; i++) {
    normalizedArray.push(i);
  }

  return normalizedArray;
};
