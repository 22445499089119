import { ReportingPeriods } from "../types";

export const formatDate = (date: string) => {
  //prevent the date from getting one day subtracted
  const convertedDate = date.replace(/-/g, "/").replace(/T.+/, "");
  //get the date
  const dateFromAPI = new Date(convertedDate);
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
    year: "numeric",
  };
  return dateFromAPI.toLocaleDateString("en-US", options);
};

export const formatTableDate = (dateFromAPI: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    month: "short",
    year: "numeric",
  };
  const formattedDate = dateFromAPI.toLocaleDateString("en-US", options);
  const month = formattedDate.split(" ")[0].toUpperCase();
  const year = formattedDate.split(" ")[1];
  return `${month} ${year}`;
};

export const formatCurrentPeriod = (dateFromAPI: Date, reportingPeriod: string) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
  };

  let formattedDate: string;
  if (reportingPeriod === "monthly") {
    const month = dateFromAPI.toLocaleDateString("en-US", { month: "short" });
    formattedDate = month.charAt(0).toUpperCase() + month.slice(1).toLowerCase();
  } else if (reportingPeriod === "quarterly") {
    const quarter = Math.ceil((dateFromAPI.getMonth() + 1) / 3);
    formattedDate = `Q${quarter}`;
  } else {
    // Invalid reporting period provided
    return "";
  }

  const year = dateFromAPI.toLocaleDateString("en-US", options);
  return `${formattedDate} ${year}`;
};

// Formatting for current statements periods from API call
const getFormattedMonth = (month: number, reportingPeriod: ReportingPeriods): string => {
  if (reportingPeriod === ReportingPeriods.Monthly) {
    const monthNames = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
    return monthNames[month - 1];
  } else if (reportingPeriod === ReportingPeriods.Quarterly) {
    const quarter = Math.ceil(month / 3);
    return `Q${quarter}`;
  }

  return "";
};

export const formatPeriodDate = (month: number, year: number, reportingPeriod: ReportingPeriods): string => {
  const formattedMonth = getFormattedMonth(month, reportingPeriod);
  return `${formattedMonth} ${year}`;
};
