import { Button, Header } from "@alterdomus-analytics/dna-ui";
import { AppHeader } from "../../components/AppHeader";
import { SideBarMenu } from "../../components/SideBarMenu";
import { ButtonStack, Container, HeaderContainer, SectionContainer, StyledTextField, StyledWrapper, TitleStyled } from "./styles";
import { Control, Controller, FormProvider, useForm, useWatch } from "react-hook-form";
import { PageTabs } from "../../components/PageTabs/PageTabs";
import { useEffect, useState } from "react";
import { EditDealTeam } from "../../components/EditDealTeam";
import { DealTeamMembers, DealTeamMembersForm, UserOptions } from "../../components/EditDealTeam/types";
import { useAppSelector } from "../../redux/hooks";
import { SingleUploadForm } from "../../components/UploadForm";
import { SingleUploadFormData } from "../../components/UploadForm/types";

export const NewDealPage = () => {
  const [tabValue, setTabValue] = useState(0);
  const [formSectionValid, setFormSectionValid] = useState(false);
  const [clearFile, setClearFile] = useState(false);
  const [dealTeamErrors, setDealTeamErrors] = useState<string[]>([]);
  const [creditAgreementErrors, setCreditAgreementErrors] = useState<string[]>([]);
  const [clearTeam, setClearTeam] = useState<boolean>(false);
  const orgId = useAppSelector((state) => state.app.user.orgId);

  const methods = useForm({
    defaultValues: { dealName: "", selectedUsers: [], file: [] },
    mode: "onBlur", // Ensure validation on blur
  });
  const {
    formState: { errors },
    control,
    trigger,
    reset,
    handleSubmit,
  } = methods;
  const formHasErrors = Object.keys(errors).length > 0;
  const dealName = useWatch({
    control,
    name: "dealName",
  });

  const selectedUsers = useWatch({
    control,
    name: "selectedUsers",
  });

  const creditAgreement = useWatch({
    control,
    name: "file",
  });
  const hasCreditAgreement = creditAgreement?.length > 0;

  useEffect(() => {
    setClearTeam(false);
    const hasDealOwner = selectedUsers?.some((m: DealTeamMembers) => m.role === UserOptions.OWNER);
    const trimmedDealName = dealName ? dealName.trim() : "";

    setFormSectionValid(Boolean(trimmedDealName) && hasDealOwner && hasCreditAgreement);
  }, [dealName, selectedUsers, tabValue, creditAgreement, hasCreditAgreement]);

  function handleClear() {
    reset();
    setTabValue(0);
    setClearTeam(true);
    setClearFile(true);
  }

  function handleTabsChange(event: React.SyntheticEvent<Element, Event>, newValue: number) {
    setTabValue(newValue);
  }

  function handleNext() {
    trigger("dealName"); // Trigger validation for dealName
    const dealTeamErrors: string[] = [];
    const fileErrors: string[] = [];
    const hasDealOwner = selectedUsers?.some((m: DealTeamMembers) => m.role === UserOptions.OWNER);

    if (!hasDealOwner) {
      dealTeamErrors.push("You need to add at least one deal owner. You can always change it later.");
    }
    setDealTeamErrors(dealTeamErrors); // Trigger validation for deal team
    if (!hasCreditAgreement) {
      fileErrors.push("The upload of the Credit Agreement is required.");
    }
    setCreditAgreementErrors(fileErrors); // Trigger validation for credit agreement file

    if (tabValue < 2 && formSectionValid) {
      setTabValue((prev) => prev + 1);
    }
  }

  const submitForm = async (data: any) => {
    if (!formHasErrors && formSectionValid) {
      console.log(data);
    }
  };

  return (
    <>
      <AppHeader />
      <StyledWrapper>
        <SideBarMenu />
        <Container item xs={12}>
          {/* Wrapper for useForm to work with useFormContext in child components */}
          <FormProvider {...methods}>
            <form encType="multipart/form-data">
              <HeaderContainer isSticky={true}>
                <Header
                  title="Add Deal"
                  width="100%"
                  children={
                    <ButtonStack flexDirection={"row"}>
                      <Button label="Cancel" variantType="tertiary" onClick={handleClear} />
                      {tabValue === 2 ? (
                        <Button
                          label={"Submit"}
                          variantType="primary"
                          onClick={handleSubmit(submitForm)}
                          disabled={!formSectionValid}
                        />
                      ) : (
                        <Button label={"Next"} variantType="primary" onClick={handleNext} />
                      )}
                    </ButtonStack>
                  }
                />
              </HeaderContainer>
              <PageTabs
                fullWidthTabs
                isSticky
                value={tabValue}
                handleChange={handleTabsChange}
                labels={["Deal Details", "Add Documents (Optional)", "Review"]}
                tabContent={[
                  <>
                    <SectionContainer p={"2.5rem 2.5rem"}>
                      <TitleStyled variant="h4" mb={"2rem"}>
                        Deal Name
                      </TitleStyled>
                      <Controller
                        name={`dealName`}
                        control={control}
                        rules={{
                          required: "The deal name is required",
                          minLength: {
                            value: 1,
                            message: "Deal name must be at least 1 character long",
                          },
                        }}
                        render={({ fieldState: { error }, field: { value, onChange, onBlur } }) => (
                          <StyledTextField
                            id="deal-name"
                            label="Deal Name"
                            size="small"
                            variant="outlined"
                            placeholder="E.g. “Deal Name”"
                            value={value || ""}
                            onChange={onChange}
                            onBlur={(e) => {
                              onBlur();
                              methods.trigger("dealName");
                            }}
                            error={error && true}
                            helperText={error ? error.message : ""}
                          />
                        )}
                      />
                    </SectionContainer>
                    <SectionContainer p={"2.5rem 2.5rem"}>
                      <TitleStyled variant="h4" mb={"1rem"}>
                        Assign Deal Team
                      </TitleStyled>
                      <EditDealTeam
                        standAlone={false}
                        companyId=""
                        orgId={orgId}
                        control={control as unknown as Control<DealTeamMembersForm, unknown>}
                        name="selectedUsers"
                        dealTeamErrors={dealTeamErrors}
                        clearTeam={clearTeam}
                      />
                    </SectionContainer>
                    <SectionContainer p={"2.5rem 2.5rem 0rem"}>
                      <TitleStyled variant="h4" mb={"-2rem"}>
                        Upload the Credit Agreement
                      </TitleStyled>
                      <SingleUploadForm
                        name="file"
                        control={control as unknown as Control<SingleUploadFormData, unknown>}
                        clearForm={clearFile}
                        fileErrors={creditAgreementErrors}
                      />
                    </SectionContainer>
                    {/* First tab END */}
                  </>,
                ]}
              />
            </form>
          </FormProvider>
        </Container>
      </StyledWrapper>
    </>
  );
};
