import { Box, Tabs as MUITabs } from "@mui/material";
import { a11yProps } from "../../utils/a11yProps";
import type { TabProps, TabPanelProps, TabIconNumber } from "./types";
import { StyledTab, StyledTabs } from "./styles";
import { NumberOne, NumberThree, NumberTwo } from "@phosphor-icons/react";

const TabPanel = ({ children, value, index, ...props }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...props}
  >
    {value === index && <Box>{children}</Box>}
  </div>
);

const getIconComponent = (iconName: string | undefined) => {
  switch (iconName) {
    case "NumberOne":
      return <NumberOne size={20} className="duocolor-grey" weight="bold" />;
    case "NumberTwo":
      return <NumberTwo size={20} className="duocolor-grey" weight="bold" />;
    case "NumberThree":
      return <NumberThree size={20} className="duocolor-grey" weight="bold" />;
    default:
      return undefined;
  }
};

export const PageTabs = ({ labels, tabContent, caseVariant, handleChange, value, fullWidthTabs, isSticky }: TabProps) => {
  const tabIcons: TabIconNumber[] = ["NumberOne", "NumberTwo", "NumberThree"];
  return (
    <StyledTabs fullWidthTabs={fullWidthTabs}>
      <Box
        sx={{ borderBottom: 1, borderColor: "divider" }}
        className={isSticky ? "pageTabs-scroller-sticky" : "pageTabs-scroller"}
      >
        <MUITabs value={value} onChange={handleChange} sx={{ minHeight: "36px" }}>
          {labels?.map((labelItem: string, index: number) => (
            <StyledTab
              key={index}
              label={labelItem}
              {...a11yProps(index)}
              icon={fullWidthTabs ? getIconComponent(tabIcons[index]) : undefined}
              sx={{ textTransform: caseVariant ? caseVariant : "none" }}
            />
          ))}
        </MUITabs>
      </Box>
      {tabContent?.map((contentItem: JSX.Element | React.ReactNode | React.ReactNode[] | string, index: number) => (
        <TabPanel key={index} value={value} index={index}>
          {contentItem}
        </TabPanel>
      ))}
    </StyledTabs>
  );
};
