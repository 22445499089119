import { Autocomplete, DocumentItem, Icon, Upload } from "@alterdomus-analytics/dna-ui";
import { Alert, AlertColor, Box, Divider, Stack, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CheckCircle, Info, ProhibitInset } from "@phosphor-icons/react";
import { WarningCircle } from "phosphor-react";
import { Fragment, useEffect, useState } from "react";
import type { Accept, FileRejection } from "react-dropzone";
import { ArrayPath, Controller, useFieldArray, UseFieldArrayProps, useFormContext } from "react-hook-form";
import noTasks from "../../assets/images/noTasks.svg";
import {
  DateContainer,
  DescriptionStyled,
  ErrorMessage,
  IconBox,
  StyledDatePicker,
  StyledDocumentItem,
  StyledSnackbar,
  StyledSubtitle,
  TitleStyled,
} from "./styles";
import { DocumentItemProps, IOption, SingleUploadFormData, SingleUploadFormProps, UploadFormProps } from "./types";
import { validateFiles } from "../../utils/files";

const validFileTypes: Accept = {
  "image/*": [".jpg", ".jpeg", ".png"],
  "application/pdf": [".pdf"],
  "application/msword": [".doc"],
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
  "text/csv": [".csv"],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
  "application/vnd.ms-excel": [".xls"],
  "text/plain": [".txt"],
};

const validSingleFileTypes: Accept = {
  "application/pdf": [".pdf"],
};

const documentOptions: IOption[] = [
  {
    label: "",
    value: "",
    id: undefined,
  },
  {
    label: "Management Accounts",
    value: "management-accounts",
    id: 1,
  },
  {
    label: "Compliance Certificates",
    value: "compliance-certificates",
    id: 2,
  },
  {
    label: "Lender Presentation",
    value: "lender-presentation",
    id: 3,
  },
  {
    label: "Budget",
    value: "budget",
    id: 4,
  },
  {
    label: "Syndication Model",
    value: "syndication-model",
    id: 5,
  },
  {
    label: "Information Memorandum",
    value: "information-memorandum",
    id: 6,
  },
];

export const UploadForm = ({ entityOptions, clearForm = false }: UploadFormProps) => {
  const [entityValue, setEntityValue] = useState<boolean>(false);
  const [fileErrorMessage, setFileErrorMessage] = useState<string[]>([]);

  const fileSizeLimit = 20e6; // 20MB
  const minDate = new Date("2000-01-01T00:00:00.000");
  const maxDate = new Date("2034-01-01T00:00:00.000");

  const {
    register,
    control,
    setValue,
    formState: { errors },
  } = useFormContext();
  const { fields, remove } = useFieldArray({
    control,
    name: "files", // unique name for our Field Array
  });

  const handleFileUpload = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const validationResult = validateFiles(acceptedFiles, fileRejections, fileSizeLimit);

    if (validationResult.errorMessage) {
      setFileErrorMessage([validationResult.errorMessage]);
      return;
    }
    if (validationResult.fileData) {
      const newFileData = validationResult.fileData.map((file) => ({
        file,
        name: file.name,
        type: file.type,
        size: file.size,
      }));

      // Set the new files in the field array
      setValue("files", [...newFileData]);
      setFileErrorMessage([]);
    }
  };

  useEffect(() => {
    setEntityValue(false);
    setFileErrorMessage([]);
    remove();
  }, [clearForm, remove]);

  return (
    <>
      {!entityOptions || entityOptions.length === 0 ? (
        <Stack>
          <TitleStyled variant="h5">
            There are no available entities for uploading documents,
            <br />
            in your portfolio
          </TitleStyled>
          <IconBox>
            <img src={noTasks} alt="No Table Data" />
          </IconBox>
        </Stack>
      ) : (
        <>
          <TitleStyled variant="h4">Upload documents</TitleStyled>
          <DescriptionStyled>First, tell us which company you want to upload the documents to?</DescriptionStyled>
          <Controller
            name={`entityName`}
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Autocomplete
                name={"Entity name"}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  setEntityValue(!!newValue);
                }}
                value={value || null}
                getOptionLabel={(option) => {
                  return option.label;
                }}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                error={error && true}
                formHelperText={error ? error.message : ""}
                sx={{ marginBottom: entityValue ? "-2rem" : "2.5rem" }}
                id="entityName"
                options={entityOptions}
              />
            )}
          />
          {entityValue && (
            <Upload
              {...register("files", {
                required: true,
              })}
              {...fields}
            >
              <Upload.DropzoneContainer>
                <Upload.Dropzone onDropCallback={handleFileUpload} accept={validFileTypes} />
              </Upload.DropzoneContainer>
              {fields &&
                fields.map((file, i) => {
                  const { id, name, type } = file as any;
                  return (
                    <>
                      <DocumentItem
                        key={id}
                        fileName={name}
                        fileType={type as DocumentItemProps["fileType"]}
                        onRemoveButtonClick={() => remove(i)}
                      >
                        <Controller
                          name={`files.${i}.documentType`}
                          control={control}
                          rules={{ required: "This field is required" }}
                          render={({ field: { value, onChange }, fieldState: { error } }) => (
                            <Autocomplete
                              name={`Document Type`}
                              placeholder="Start typing any document type ..."
                              value={
                                value
                                  ? documentOptions.find((option) => {
                                      return value === option.value;
                                    }) ?? null
                                  : null
                              }
                              getOptionLabel={(option) => {
                                return option.label;
                              }}
                              onChange={(event: any, newValue) => {
                                onChange(newValue ? newValue.value : null);
                              }}
                              error={error && true}
                              formHelperText={error ? error.message : ""}
                              id="documentType"
                              options={documentOptions}
                            />
                          )}
                        />
                        <Box
                          sx={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                            width: "100%",
                            gap: "8px",
                            marginBottom: "16px",
                            marginTop: "16px",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateContainer>
                              <Controller
                                name={`files.${i}.financialYear`}
                                control={control}
                                rules={{ required: "This field is required" }}
                                defaultValue={null}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                  <StyledDatePicker
                                    label="Financial Year"
                                    openTo="year"
                                    views={["year"]}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    inputFormat="YYYY"
                                    value={value}
                                    onChange={onChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`files.${i}.financialYear`}
                                        error={error && true}
                                        helperText={error ? error.message : ""}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </DateContainer>
                            <DateContainer>
                              <Controller
                                name={`files.${i}.financialPeriod`}
                                control={control}
                                rules={{ required: "This field is required" }}
                                defaultValue={null}
                                render={({ field: { value, onChange }, fieldState: { error } }) => (
                                  <StyledDatePicker
                                    label="Financial Period"
                                    openTo="month"
                                    views={["month"]}
                                    minDate={minDate}
                                    maxDate={maxDate}
                                    inputFormat="MMM"
                                    value={value}
                                    onChange={onChange}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        name={`files.${i}.financialPeriod`}
                                        error={error && true}
                                        helperText={error ? error.message : ""}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </DateContainer>
                          </LocalizationProvider>
                        </Box>
                      </DocumentItem>
                    </>
                  );
                })}
            </Upload>
          )}
          {fileErrorMessage.length > 0 && (
            <Stack display={"flex"} flexDirection={"row"} alignItems={"flex-start"} color={"#9F372F"}>
              <WarningCircle size={18} />
              {fileErrorMessage.map((errorMessage, i) => (
                <ErrorMessage key={i}>{errorMessage}</ErrorMessage>
              ))}
            </Stack>
          )}
          {errors.files && (
            <Stack display={"flex"} flexDirection={"row"} alignContent={"center"} color={"#9F372F"}>
              <WarningCircle size={18} />
              <ErrorMessage>{errors.files && "Upload of files is required"}</ErrorMessage>
            </Stack>
          )}
        </>
      )}
    </>
  );
};

export const SingleUploadForm = ({
  name,
  clearForm = false,
  fileErrors = [],
}: UseFieldArrayProps<SingleUploadFormData, ArrayPath<SingleUploadFormData>> & SingleUploadFormProps) => {
  const [fileErrorMessage, setFileErrorMessage] = useState<string[]>([]);
  const [openNotification, setOpenNotification] = useState<boolean>(true);
  const [notificationAppearance, setNotificationAppearance] = useState<AlertColor>("info");

  const fileSizeLimit = 20e6; // 20MB
  const iconColorMap = { info: "#1A50DD", success: "#019886", warning: "#E1802B", error: "#E72215" };
  const iconMap = {
    info: Info,
    error: ProhibitInset,
    warning: WarningCircle,
    success: CheckCircle,
  };

  const { register, control, setValue } = useFormContext<SingleUploadFormData>();
  const { fields, remove } = useFieldArray({
    control,
    name, // file - unique name for our Field Array
  });

  const handleFileUpload = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    const validationResult = validateFiles(acceptedFiles, fileRejections, fileSizeLimit);

    if (validationResult.errorMessage) {
      setFileErrorMessage([validationResult.errorMessage]);
      setOpenNotification(true);
      if (validationResult.errorMessage === "Upload of a file is required") {
        setNotificationAppearance("error");
      } else if (validationResult.errorMessage === "This is larger than the 20MB limit") {
        setNotificationAppearance("warning");
      } else {
        setNotificationAppearance("warning");
      }
      return;
    }

    if (validationResult.fileData) {
      // Take only the first file from acceptedFiles
      const newFile = validationResult.fileData[0];

      const newFileData = {
        file: newFile,
        name: newFile.name,
        type: newFile.type,
        size: newFile.size,
      };

      // Set the new file in the field array, clearing any existing files
      setValue("file", [newFileData]);
      setFileErrorMessage([]);
    }
  };

  useEffect(() => {
    setFileErrorMessage([]);
    remove();
  }, [clearForm, remove]);

  useEffect(() => {
    if (fileErrors && fileErrors.length > 0) {
      setFileErrorMessage(fileErrors);
      setOpenNotification(true);
      setNotificationAppearance("error");
    }
  }, [fileErrors]);

  function handleCloseNotification() {
    setOpenNotification(false);
    setFileErrorMessage([]);
  }

  return (
    <>
      {fileErrorMessage.length > 0 && (
        <>
          {fileErrorMessage.map((error, index) => (
            <StyledSnackbar
              key={index}
              open={openNotification}
              onClose={handleCloseNotification}
              sx={{ position: "static", marginBottom: "-2.5rem", paddingTop: "3rem", zIndex: 100 }}
              appearance={notificationAppearance}
            >
              <Alert
                onClose={handleCloseNotification}
                severity={notificationAppearance}
                variant="filled"
                icon={
                  <Icon component={iconMap[notificationAppearance]} color={iconColorMap[notificationAppearance]} weight="fill" />
                }
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </StyledSnackbar>
          ))}
        </>
      )}
      <Upload
        {...register("file", {
          required: true,
        })}
        {...fields}
      >
        {!fields[0]?.id && (
          <Upload.DropzoneContainer>
            <Upload.Dropzone onDropCallback={handleFileUpload} accept={validSingleFileTypes} />
          </Upload.DropzoneContainer>
        )}
        {fields &&
          fields.map((file, i) => {
            const { id, name, type } = file as any;
            return (
              <Fragment key={id}>
                <Divider sx={{ marginTop: "2rem", marginBottom: "1rem" }} />
                <StyledSubtitle>Credit Agreement</StyledSubtitle>
                <StyledDocumentItem direction={"row"}>
                  <DocumentItem
                    fileName={name}
                    fileType={type as DocumentItemProps["fileType"]}
                    onRemoveButtonClick={() => remove(i)}
                  />
                  <CheckCircle size={20} weight="fill" className="document-check-icon" />
                </StyledDocumentItem>
                <Divider sx={{ marginTop: "-0.5rem", marginBottom: "2.5rem" }} />
              </Fragment>
            );
          })}
      </Upload>
    </>
  );
};
