import ApiClient from "../api";
import type { Dayjs } from "dayjs";

export interface UploadFormData {
  [key: string]: any;
  entityName: any;
  files: UploadFiles[];
}

export interface UploadFiles {
  file: File;
  fileName: string;
  fileType: string;
  fileSize: number;
  documentType: string;
  financialYear: Dayjs | null;
  financialPeriod: Dayjs | null;
}

const postFilesAndMetaData = async (formData: UploadFormData) => {
  // Format financialPeriods, financialYears in order to send only month & year values respectively
  const finPeriods = formData.files.map((file) => {
    return file.financialPeriod ? file.financialPeriod.format("MMM") : "";
  });

  const finYears = formData.files.map((file) => {
    return file.financialYear ? file.financialYear.format("YYYY") : "";
  });
  // Get only documentTypes value
  const documentTypes = formData.files.map((file) => file.documentType);
  const data = new FormData();

  // Append finPeriods and finYears as arrays
  finPeriods.forEach((period, index) => {
    data.append(`finPeriods[${index}]`, period);
  });

  finYears.forEach((year, index) => {
    data.append(`finYears[${index}]`, year);
  });

  documentTypes.forEach((type, index) => {
    data.append(`documentTypes[${index}]`, type);
  });

  // Append entityName object
  for (const key in formData.entityName) {
    if (formData.entityName.hasOwnProperty(key)) {
      data.append(`entityName.${key}`, formData.entityName[key]);
    }
  }

  // Append the files
  formData.files.forEach((file, index) => {
    data.append(`files[${index}][file]`, file.file);
    data.append(`files[${index}][name]`, file.fileName);
    data.append(`files[${index}][type]`, file.fileType);
  });

  try {
    const response = await ApiClient.post(`/upload/`, data, {});

    return response.data;
  } catch (err) {
    return { success: false, messages: [JSON.stringify(err)] };
  }
};

export { postFilesAndMetaData };
