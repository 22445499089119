import { GridSortModel } from "@mui/x-data-grid";
import { MultiSelectItemType } from "../../components/MultiSelect";
import {
  AnalyzerRow,
  ApiResponseType,
  Company,
  DealOverview,
  DealCovenant,
  DealRevenueEbitda,
  PageMetaDataType,
  ReportingPeriods,
} from "../../types";
import ApiClient from "../api";
import { DealTeamMembers } from "../../components/EditDealTeam/types";

const itemsPerPage = 20;

const getAllDeals = async (portfolioId: string, pageNumber: number, sortModel?: GridSortModel) => {
  try {
    const portfolioQuery = portfolioId ? `&portfolioId=${portfolioId}` : ``;
    let sortQuery = "&sortfield=company_name&sortorder=asc";

    if (sortModel && sortModel.length > 0) {
      sortQuery = `&sortfield=${sortModel[0].field}&sortorder=${sortModel[0].sort}`;
    }

    const response = await ApiClient.get<ApiResponseType<DealOverview>>(
      `/deals?page=${pageNumber}&take=${itemsPerPage}${sortQuery}${portfolioQuery}`
    );
    const rows = await getDealsRows(response.data.data, response.data.meta);
    return rows;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error();
  }
};

const getAllDealNames = async (portfolioId: string) => {
  try {
    const fundIds: MultiSelectItemType[] = [];
    const sectorIds: MultiSelectItemType[] = [];
    const countryIds: MultiSelectItemType[] = [];
    const filters = {
      portfolioId,
      fundIds,
      sectorIds,
      countryIds,
    };
    let sortQuery = "&sortfield=company_name&sortorder=asc";

    const response = await ApiClient.post<ApiResponseType<DealOverview>>(`/deals/filters?page=1&take=900${sortQuery}`, filters);

    // Extract the company names and ids from the response
    const dealNames = response.data.data.map((deal) => ({
      label: deal.companyName,
      value: deal.companyName,
      id: deal.companyId,
    }));

    return dealNames;
  } catch (err) {
    return { success: false, messages: [JSON.stringify(err)] };
  }
};

const getAllRevenueEbitda = async (customerId: number, portfolioId: string, pageNumber: number, sortModel?: GridSortModel) => {
  try {
    let sortQuery = `&sortfield=company_name&sortorder=asc`;

    if (sortModel && sortModel.length > 0) {
      sortQuery = `&sortfield=${sortModel[0].field}&sortorder=${sortModel[0].sort}`;
    }

    const response = await ApiClient.get<ApiResponseType<DealRevenueEbitda>>(
      `/revenue-ebitda?page=${pageNumber}&take=${itemsPerPage}&portfolio=${portfolioId}&customerId=${customerId}${sortQuery}`
    );
    const rows = await getRevEbitdaRows(response.data.data, response.data.meta);
    return rows;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error();
  }
};

const getRevenueEbitdaByFilters = async (
  portfolioId: string,
  pageNumber: number,
  funds: MultiSelectItemType[],
  sectors: MultiSelectItemType[],
  countries: MultiSelectItemType[],
  search: string,
  parValues: (number | null)[],
  leverages: (number | null)[],
  revenuesVsBudget: (number | null)[],
  revenuesVsLastYear: (number | null)[],
  sortModel?: GridSortModel,
  period?: ReportingPeriods
) => {
  const fundIds = funds ? await getIds(funds) : [];
  const sectorIds = sectors ? await getIds(sectors) : [];
  const countryIds = countries ? await getIds(countries) : [];
  const parValue = { min: parValues[0], max: parValues[1] };
  const leverage = { min: leverages[0], max: leverages[1] };
  const revenueVsBudget = { min: revenuesVsBudget[0], max: revenuesVsBudget[1] };
  const revenueVsLastYear = { min: revenuesVsLastYear[0], max: revenuesVsLastYear[1] };
  const data = {
    portfolioId,
    fundIds,
    sectorIds,
    countryIds,
    search,
    parValue,
    leverage,
    revenueVsBudget,
    revenueVsLastYear,
    period,
  };

  let sortQuery = `&sortfield=company_name&sortorder=asc`;

  if (sortModel && sortModel.length > 0) {
    sortQuery = `&sortfield=${sortModel[0].field}&sortorder=${sortModel[0].sort}`;
  }

  const response = await ApiClient.post<ApiResponseType<DealRevenueEbitda>>(
    `/revenue-ebitda/filters?page=${pageNumber}&take=${itemsPerPage}${sortQuery}`,
    data
  );

  const rows = await getRevEbitdaRows(response.data.data, response.data.meta);
  return rows;
};

const getAllCovenants = async (customerId: number, portfolioId: string, pageNumber: number, sortModel?: GridSortModel) => {
  try {
    let sortQuery = `&sortfield=company_name&sortorder=asc`;

    if (sortModel && sortModel.length > 0) {
      sortQuery = `&sortfield=${sortModel[0].field}&sortorder=${sortModel[0].sort}`;
    }

    const response = await ApiClient.get<ApiResponseType<DealCovenant>>(
      `/covenants?portfolioId=${portfolioId}&customerId=${customerId}&page=${pageNumber}&take=${itemsPerPage}${sortQuery}`
    );
    const rows = await getCovenantsRows(response.data.data, response.data.meta);
    return rows;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error();
  }
};

const getCovenantsByFilters = async (
  portfolioId: string,
  pageNumber: number,
  funds: MultiSelectItemType[],
  sectors: MultiSelectItemType[],
  countries: MultiSelectItemType[],
  search: string,
  parValues: (number | null)[],
  leverages: (number | null)[],
  revenuesVsBudget: (number | null)[],
  revenuesVsLastYear: (number | null)[],
  sortModel?: GridSortModel
) => {
  const fundIds = funds ? await getIds(funds) : [];
  const sectorIds = sectors ? await getIds(sectors) : [];
  const countryIds = countries ? await getIds(countries) : [];
  const parValue = { min: parValues[0], max: parValues[1] };
  const leverage = { min: leverages[0], max: leverages[1] };
  const revenueVsBudget = { min: revenuesVsBudget[0], max: revenuesVsBudget[1] };
  const revenueVsLastYear = { min: revenuesVsLastYear[0], max: revenuesVsLastYear[1] };
  const data = {
    portfolioId,
    fundIds,
    sectorIds,
    countryIds,
    search,
    parValue,
    leverage,
    revenueVsBudget,
    revenueVsLastYear,
  };

  let sortQuery = `&sortfield=company_name&sortorder=asc`;

  if (sortModel && sortModel.length > 0) {
    sortQuery = `&sortfield=${sortModel[0].field}&sortorder=${sortModel[0].sort}`;
  }

  const response = await ApiClient.post<ApiResponseType<DealCovenant>>(
    `/portfolio_analyzer/covenants?page=${pageNumber}&take=${itemsPerPage}${sortQuery}`,
    data
  );

  const rows = await getCovenantsRows(response.data.data, response.data.meta);
  return rows;
};

const getDealById = async (companyId: number) => {
  try {
    const response = await ApiClient.get<Company>(`/deals/${companyId}`);
    return response.data;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error();
  }
};

const updateDealDetails = async (companyId: string, field: string, value: any) => {
  try {
    const data = {
      companyId,
      field,
      value,
    };

    const response = await ApiClient.post(`/deals/update`, data);

    return response.data;
  } catch (err) {
    return { success: false, messages: [JSON.stringify(err)] };
  }
};

const updateDealTeam = async (companyId: string, selectedUsers: DealTeamMembers[]) => {
  const formData = new FormData();

  // Append selected users data to formData
  selectedUsers.forEach((user, index) => {
    formData.append(`selectedUsers[${index}].company_id`, companyId);
    formData.append(`selectedUsers[${index}].user_id`, user.user_id);
    formData.append(`selectedUsers[${index}].value`, user.value);
    formData.append(`selectedUsers[${index}].role`, user.role);
  });

  try {
    const response = await ApiClient.post(`/deals/update/team/`, formData, {});
    return response.data;
  } catch (err) {
    return { success: false, messages: [JSON.stringify(err)] };
  }
};

const getDealsByFilters = async (
  portfolioId: string,
  pageNumber: number,
  funds: MultiSelectItemType[],
  sectors: MultiSelectItemType[],
  countries: MultiSelectItemType[],
  search: string,
  parValues: (number | null)[],
  leverages: (number | null)[],
  revenuesVsBudget: (number | null)[],
  revenuesVsLastYear: (number | null)[],
  sortModel?: GridSortModel
) => {
  try {
    const fundIds = funds ? await getIds(funds) : [];
    const sectorIds = sectors ? await getIds(sectors) : [];
    const countryIds = countries ? await getIds(countries) : [];
    const parValue = { min: parValues[0], max: parValues[1] };
    const leverage = { min: leverages[0], max: leverages[1] };
    const revenueVsBudget = { min: revenuesVsBudget[0], max: revenuesVsBudget[1] };
    const revenueVsLastYear = { min: revenuesVsLastYear[0], max: revenuesVsLastYear[1] };
    const data = {
      portfolioId,
      fundIds,
      sectorIds,
      countryIds,
      search,
      parValue,
      leverage,
      revenueVsBudget,
      revenueVsLastYear,
    };

    let sortQuery = `&sortfield=company_name&sortorder=asc`;

    if (sortModel && sortModel.length > 0) {
      sortQuery = `&sortfield=${sortModel[0].field}&sortorder=${sortModel[0].sort}`;
    }

    const response = await ApiClient.post<ApiResponseType<DealOverview>>(
      `/deals/filters?page=${pageNumber}&take=${itemsPerPage}${sortQuery}`,
      data
    );
    const rows = await getDealsRows(response.data.data, response.data.meta);
    return rows;
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error();
  }
};

const getDropdownOptions = async (
  portfolioId: string,
  funds?: MultiSelectItemType[],
  sectors?: MultiSelectItemType[],
  countries?: MultiSelectItemType[],
  parValues?: (number | null)[],
  leverages?: (number | null)[],
  revenuesVsBudget?: (number | null)[],
  revenuesVsLastYear?: (number | null)[],
  period?: ReportingPeriods,
  search?: string
) => {
  try {
    const fundIds = funds ? await getIds(funds) : [];
    const sectorIds = sectors ? await getIds(sectors) : [];
    const countryIds = countries ? await getIds(countries) : [];
    const parValue = parValues && parValues.length ? { min: parValues[0], max: parValues[1] } : undefined;
    const leverage = leverages && leverages.length ? { min: leverages[0], max: leverages[1] } : undefined;
    const revenueVsBudget =
      revenuesVsBudget && revenuesVsBudget.length ? { min: revenuesVsBudget[0], max: revenuesVsBudget[1] } : undefined;
    const revenueVsLastYear =
      revenuesVsLastYear && revenuesVsLastYear.length ? { min: revenuesVsLastYear[0], max: revenuesVsLastYear[1] } : undefined;
    const data = {
      portfolioId,
      fundIds,
      sectorIds,
      countryIds,
      search,
      parValue,
      leverage,
      revenueVsBudget,
      revenueVsLastYear,
      period,
    };
    const response = await ApiClient.post<string[]>(`/deals/search`, data);
    return response.data.map((d: string) => {
      return { label: d, value: d, isClearable: true };
    });
  } catch (err) {
    console.error("api error:");
    console.error(err);
    throw Error();
  }
};

const getIds = async (values: MultiSelectItemType[]) => {
  const ids: string[] = [];
  for await (const v of values) {
    ids.push(v.id);
  }
  return ids;
};

const getDealsRows = async (deals: DealOverview[], meta: PageMetaDataType) => {
  const rows: AnalyzerRow[] = [];

  for await (const d of deals) {
    const newRow: AnalyzerRow = {
      id: d.dealId,
      company_id: d.companyId,
      company_trade_name: d.companyTradeName,
      period: d.period,
      periodType: d.reportingCycle,
      sponsors: d.sponsors,
      sector: d.sector,
      currency: d.currency,
      country: d.country,
      par_value: d.parValue,
      all_in_rate: d.allInRate,
      seniority: d.seniority,
      internal_rating: d.internalRating,
      funds: d.funds,
      actual: "",
      enableConversions: d.enableConversions,
    };
    rows.push(newRow);
  }
  return {
    rows,
    meta,
  };
};

const getRevEbitdaRows = async (dealsRevEbitda: DealRevenueEbitda[], meta: PageMetaDataType) => {
  const rows: AnalyzerRow[] = [];

  for await (const d of dealsRevEbitda) {
    const newRow: AnalyzerRow = {
      id: d.companyId,
      company_trade_name: d.companyTradeName,
      period: d.period,
      periodType: d.reportingCycle,
      currency: d.currency,
      revenueActual: d.revenueActual == null ? "-" : d.revenueActual,
      revenueVsBudget: d.revenueVsBudget,
      revenueVsLastYear: d.revenueVsLastYear,
      ebitdaActual: d.ebitdaActual,
      ebitdaVsBudget: d.ebitdaVsBudget,
      ebitdaVsLastYear: d.ebitdaVsLastYear,
      leverageOpening: d.openingLeverage,
      leverageCurrent: d.leverage,
    };
    rows.push(newRow);
  }

  return {
    rows,
    meta,
  };
};

const getCovenantsRows = async (dealsCovenant: DealCovenant[], meta: PageMetaDataType) => {
  const rows: AnalyzerRow[] = [];

  for await (const d of dealsCovenant) {
    const newRow: AnalyzerRow = {
      id: d.companyId,
      company_trade_name: d.companyTradeName,
      period: d.period,
      periodType: d.reportingCycle,
      currency: d.currency,
      opening_leverage: d.openingLeverage,
      current_leverage: d.leverage,
      leverage_covenant_test: d.leverageCovenantTest,
      leverage_headroom: d.leverageHeadroom,
      interest_current: d.interestCurrent,
      interest_covenant_test: d.interestCovenantTest,
      interest_headroom: d.interestHeadroom,
      cash_current: d.cashCurrent,
      cash_covenant_test: d.cashCovenantTest,
      cash_headroom: d.cashHeadroom,
    };
    rows.push(newRow);
  }

  return {
    rows,
    meta,
  };
};

export {
  getAllCovenants,
  getAllDealNames,
  getAllDeals,
  getAllRevenueEbitda,
  getCovenantsByFilters,
  getDealById,
  getDealsByFilters,
  getDropdownOptions,
  getRevenueEbitdaByFilters,
  updateDealDetails,
  updateDealTeam,
};
