import { Box, Tab, styled } from "@mui/material";

export const StyledTab = styled(Tab)`
  font-size: 14px;
  align-items: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 10px 12px;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  :first-of-type {
    margin-left: 0;
  }
`;

interface StyledTabsProps {
  fullWidthTabs?: boolean;
}

export const StyledTabs = styled(Box, {
  shouldForwardProp: (prop) => prop !== "fullWidthTabs",
})(
  ({ fullWidthTabs }: StyledTabsProps) =>
    fullWidthTabs && {
      minWidth: "",
      "& .MuiBox-root.pageTabs-scroller-sticky ": { backgroundColor: "#ffffff", position: "sticky", top: "9.5rem", zIndex: 1000 },
      "& .MuiBox-root.pageTabs-scroller": { backgroundColor: "#ffffff" },
      "& .MuiTabs-root ": { paddingLeft: "2rem", paddingRight: "2rem" },
      "& div > div > .MuiTabs-flexContainer": {
        justifyContent: "space-between",
        "& .MuiButtonBase-root": {
          flexFlow: "row",
          maxWidth: "100%",
          minHeight: "auto",
          margin: 0,
          padding: "0px 12px",
          flex: 1,
          justifyContent: "flex-start",
          alignItems: "center",
          "& svg": { marginBottom: 0, marginRight: "0.5rem" },
          "& svg.duocolor-grey": {
            padding: 4,
            backgroundColor: "#E8EEEE",
            borderRadius: "50%",
          },
        },
        "& .MuiButtonBase-root.Mui-selected": {
          "& svg.duocolor-grey": {
            padding: 4,
            backgroundColor: "#1A50DD",
            fill: "#ffffff",
            borderRadius: "50%",
          },
        },
        "& .MuiButtonBase-root:first-of-type": {
          paddingLeft: "0px",
        },
      },
    }
);
