import { Box, Grid, Stack, TextField, Typography, styled } from "@mui/material";

export const Container = styled(Grid)`
  min-height: calc(100vh - 64px);
  background-color: #fafafa;
  border-radius: 0px;
  width: 100%;
  margin-left: 78px;
`;

export const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const HeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== "isSticky",
})<{ isSticky?: boolean }>(({ theme, isSticky }) => ({
  ...(isSticky && { position: "sticky", top: "4rem" }),
  padding: "1.5rem 2rem",
  backgroundColor: theme.palette.common.white,
  zIndex: 1000,
  "& .MuiBox-root": {
    "& .MuiTypography-h4": { fontSize: "1.75rem", lineHeight: "2.5rem" },
  },
}));

export const ButtonStack = styled(Stack)`
  .MuiButtonBase-root.MuiButton-root {
    color: #1a50dd;
    :first-of-type {
      margin-right: 0.5rem;
    }
  }
  .MuiButton-root.MuiButton-contained {
    color: #fff;
    background-color: #1a50dd;
  }
  .MuiButton-root.MuiButton-contained:disabled {
    color: #8f8f8f;
    background-color: #e4e4e4;
  }
`;

export const SectionContainer = styled(Box)({
  maxWidth: "680px",
  margin: "2.25rem auto 2.25rem",
  backgroundColor: "#fff",
  borderRadius: "6px",
  border: "1px solid #D4D6D6",
});

export const TitleStyled = styled(Typography)(() => ({
  textAlign: "center",
  fontSize: "1.375rem",
  lineHeight: "1.27",
}));

export const StyledTextField = styled(TextField)({
  width: "100%",
  "& > label": { fontSize: "14px" },
  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    fontSize: "14px",
  },
  "& .MuiFormLabel-root.MuiInputLabel-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0B77B1",
    borderWidth: "1px",
  },
  "& .MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#0B77B1",
  },
  "& .MuiFormHelperText-root": {
    color: "#586463",
    margin: 0,
    marginTop: "0.5rem",
  },
});
