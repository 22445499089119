import { Button, LinearProgress } from "@alterdomus-analytics/dna-ui";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  Icon,
  InputAdornment,
  InputBase,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CaretDown, X } from "@phosphor-icons/react";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { UUID } from "crypto";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ArrayPath, Controller, UseFieldArrayProps, useFieldArray, useFormState } from "react-hook-form";
import { getAllUsersList } from "../../../services/users";
import { memberAlphabeticalSort } from "../../../utils/users";
import {
  ButtonStack,
  DealTeamSearch,
  ItemPartStyled,
  LoaderContainer,
  SearchItem,
  StyledChip,
  StyledFormControlLabel,
  StyledMenuItem,
  StyledSecondaryButton,
} from "../styles";
import { DealTeamMembersForm, IOptionDealTeam, UserOptions, UserRole } from "../types";
import { DealTeamFormProps } from "./types";

export const teamRoleOptions: UserRole[] = [
  { id: UserOptions.OWNER, name: "Deal Owner" },
  { id: UserOptions.MEMBER, name: "Team Member" },
  { id: UserOptions.REMOVE, name: "Remove from deal" },
];

export const DealTeamForm = ({
  control,
  name,
  orgId,
  currentUser,
  standAlone,
  loading,
  handleClear,
  setErrors,
  setAppearance,
  clearCurrentUserChecked = false,
}: UseFieldArrayProps<DealTeamMembersForm, ArrayPath<DealTeamMembersForm>> & DealTeamFormProps) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [allUsers, setAllUsers] = useState<IOptionDealTeam[]>([]);
  const [searchOptions, setSearchOptions] = useState<IOptionDealTeam[]>([]);
  const [usersSelected, setUsersSelected] = useState<IOptionDealTeam[]>([]);
  const [searchTimerId, setSearchTimerId] = useState<number | null>(null);
  const [loadingSearchOptions, setLoadingSearchOptions] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [hiddenMembers, setHiddenMembers] = useState<string[]>([]);
  // Tracking of formState in child
  const {
    errors: { selectedUsers: fieldError },
  } = useFormState<DealTeamMembersForm>({ control });
  const { fields, remove, append, update } = useFieldArray({
    control,
    name, // name for our Field Array
  });

  useEffect(() => {
    const currentUserExists = fields.find((field) => field.user_id === currentUser[0].user_id);

    if (currentUserExists) setIsChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  async function onSearchFocus(event: React.FocusEvent<HTMLInputElement>) {
    setLoadingSearchOptions(true);
    try {
      const response = await getAllUsersList(orgId ? orgId : "");
      const users = response.value.map((user: { id: UUID; displayName: string; mail: string }) => ({
        user_id: user.id,
        label: user.displayName,
        value: user.displayName,
        email: user.mail,
      }));

      // Set the search options, allUsers and loading state
      setAllUsers(users);
      setSearchOptions(users);
      setLoadingSearchOptions(false);
    } catch (error) {
      console.error("Error fetching user list:", error);
      // Handle error here
      setLoadingSearchOptions(false);
    }
  }

  // Sort alphabetically
  const sortedOptions = useMemo(() => {
    return memberAlphabeticalSort(allUsers || []).map((o) => ({
      ...o,
      name: o.value,
    }));
  }, [allUsers]);

  function onSearchChanged(event: React.ChangeEvent<HTMLInputElement>) {
    searchTimerId && window.clearTimeout(searchTimerId);
    if (event?.type === "change") {
      let search = event.target?.value;
      search = search ? search : "";
      setSearchTerm(search);
      if (search.trim()) {
        const timerId = window.setTimeout(() => {
          updateSearchOptions(search);
        }, 300);
        setSearchTimerId(timerId);
        return;
      }
      // If the search term is empty, show all users
      setSearchOptions(sortedOptions);
    }
  }
  const updateSearchOptions = useCallback(
    (search: string) => {
      setLoadingSearchOptions(true);
      const filteredOptions = sortedOptions.filter((user) => user.value.toLowerCase().includes(search.toLowerCase()));
      setSearchOptions(filteredOptions);
      setLoadingSearchOptions(false);
    },
    [sortedOptions]
  );

  function onUserSelected(value: any) {
    let newValue: IOptionDealTeam[] | null;
    if (value === null || value.length === 0) {
      newValue = [];
      setSearchOptions([]);
    } else {
      // Handle selected values
      newValue = [
        ...value.map((option: IOptionDealTeam) => ({
          label: option.label,
          value: option.value,
          user_id: option.user_id,
          email: option.email,
          isClearable: true,
        })),
      ];
    }
    // Set the new value
    setUsersSelected(newValue);
    setSearchTerm("");
  }

  function matchUser(teamMember: IOptionDealTeam) {
    const userExists = fields.find((field) => field.user_id === teamMember.user_id);
    const userHidden = hiddenMembers.some((userId) => userId === teamMember.user_id);

    const appendUser = () => {
      append({
        label: teamMember.label,
        value: teamMember.value,
        email: teamMember.email,
        user_id: teamMember.user_id,
        role: UserOptions.MEMBER,
      });
    };
    const updateUserRole = (index: number, role: UserOptions) => {
      update(index, { ...fields[index], role });
    };

    if (userExists && !userHidden) {
      setErrors([`${teamMember.label} is already a member of the Deal team.`]);
      setAppearance("warning");
    } else if (userExists && userHidden) {
      // Update the role in the fields array
      const userIndex = fields.findIndex((field) => field.user_id === teamMember.user_id);

      if (userIndex !== -1) {
        updateUserRole(userIndex, UserOptions.MEMBER);
      }
      // Remove the user from hiddenMembers
      setHiddenMembers((prev) => prev.filter((userId) => userId !== teamMember.user_id));
      setErrors([]);
    } else if (!userExists) {
      appendUser();
      setErrors([]);
    }
  }

  function onUsersAdd(e: any) {
    const selectedUsers: IOptionDealTeam[] = usersSelected
      ? usersSelected.map((option) => ({
          label: option.label,
          value: option.value,
          user_id: option.user_id,
          email: option.email,
        }))
      : [];
    selectedUsers.forEach((u) => matchUser(u));
    // Reset the selected users in the Autocomplete after adding them
    setIsChecked(false);
    setSearchTerm("");
    setSearchOptions([]);
    setUsersSelected([]);
  }

  function handleCheckboxChange(event: React.ChangeEvent<HTMLInputElement>) {
    const isChecked = event.target.checked;
    setIsChecked(isChecked);

    const current = currentUser[0];
    const currentUserExists = fields.find((field) => field.user_id === current.user_id);
    const currentUserHidden = hiddenMembers.some((userId) => userId === current.user_id);

    const appendUser = () => {
      append({
        label: current.label,
        value: current.value,
        email: current.email,
        user_id: current.user_id,
        role: UserOptions.OWNER,
      });
    };
    const removeUser = (index: number) => {
      if (index !== -1) {
        remove(index);
      }
    };
    const updateUserRole = (index: number, role: UserOptions) => {
      update(index, { ...fields[index], role });
    };
    const removeFromHiddenMembers = () => {
      setHiddenMembers((prev) => prev.filter((userId) => userId !== current.user_id));
    };

    if (isChecked) {
      if (!currentUserExists) {
        appendUser();
      } else if (currentUserExists && currentUserHidden) {
        // Update current user role from REMOVE to OWNER when current user re-added
        const currentUserIndex = fields.findIndex((field) => field.user_id === current.user_id);
        updateUserRole(currentUserIndex, UserOptions.OWNER);
        // Remove current user from hidden members
        removeFromHiddenMembers();
      }
    } else {
      const currentUserIndex = fields.findIndex((field) => field.user_id === current.user_id);
      removeUser(currentUserIndex);
      setUsersSelected((prev) => prev.filter((user) => user.user_id !== current.user_id));
    }
  }

  // Check if current user hidden, if so remove from usersSelected state and uncheck checkbox, when hiddenMembers array changes.
  useEffect(() => {
    const currentUserHidden = hiddenMembers.some((userId) => userId === currentUser[0]?.user_id);
    if (currentUserHidden) {
      setIsChecked(false);
      setUsersSelected((prev) => prev.filter((user) => user.user_id !== currentUser[0].user_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenMembers]);

  useEffect(() => {
    setIsChecked(false);
    setUsersSelected([]);
  }, [clearCurrentUserChecked]);

  return (
    <>
      <Stack direction={"row"} mb={"0.5rem"} alignItems={"center"}>
        <DealTeamSearch
          id="deal-team-search"
          disableCloseOnSelect={true}
          size="small"
          multiple
          forcePopupIcon={false}
          clearOnBlur={true}
          value={usersSelected || ""}
          inputValue={searchTerm}
          options={searchOptions}
          renderInput={(params) => (
            <TextField
              {...params}
              id="deal-team-search-input"
              label="Add people"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <CaretDown />
                  </InputAdornment>
                ),
              }}
            />
          )}
          getOptionLabel={(option: any) => {
            return typeof option === "string" ? option : option.label;
          }}
          renderOption={(props, option: any) => {
            const matches = match(option.value, searchTerm, { insideWords: true });
            const parts = parse(option.value, matches);
            const userId = option.user_id;
            // Check if the current member is selected from the options list
            const isChecked = usersSelected.some((selectedOption) => selectedOption.user_id === option.user_id);
            return (
              <li {...props} id={`user-search-option-${userId}`} key={userId}>
                <SearchItem e2e-test-id={`user-search-option-${option.label}`}>
                  <StyledFormControlLabel
                    control={<Checkbox checked={isChecked} size="small" />}
                    label={parts.map((part, index: number) => (
                      <ItemPartStyled key={index} highlight={part.highlight}>
                        {part.text}
                      </ItemPartStyled>
                    ))}
                  />

                  <Typography variant="body2">{option.email}</Typography>
                </SearchItem>
              </li>
            );
          }}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option: any, index) => (
              <StyledChip
                {...getTagProps({ index })}
                label={option.label}
                size="small"
                deleteIcon={<Icon component={X} size={12} />}
              />
            ));
          }}
          isOptionEqualToValue={(option: any, value: any) => option.user_id === value.user_id}
          loading={loadingSearchOptions}
          disableClearable={true}
          onChange={(e, value) => onUserSelected(value)}
          onInputChange={(e: any) => onSearchChanged(e)}
          onFocus={(e: any) => onSearchFocus(e)}
          e2e-test-id={`user-search-input`}
        />
        <StyledSecondaryButton
          label="Add"
          variantType="secondary"
          size="small"
          onClick={(e) => onUsersAdd(e)}
          e2e-test-id={`add-users-btn`}
        />
      </Stack>
      <StyledFormControlLabel
        control={<Checkbox id="assign-myself-check" checked={isChecked} onChange={handleCheckboxChange} size="small" />}
        label="Assign deal to myself"
        e2e-test-id={`assign-current-user-checkbox`}
      />
      {fields.length > 0 && <Divider />}
      {loading && (
        <LoaderContainer>
          <LinearProgress variant={"query"} />
        </LoaderContainer>
      )}
      <Stack paddingX={5}>
        {fields.map((field, i) => {
          const isHidden = hiddenMembers.includes(field.user_id);
          return (
            <Box key={field.id} e2e-test-id={`selected-user-${i}`} sx={{ display: isHidden ? "none" : "block" }}>
              <Stack direction="row" sx={{ height: 56 }} alignItems="center">
                <Typography
                  id={`selected-user-${i}-name`}
                  variant="subtitle1"
                  flexGrow={1}
                  component="p"
                  e2e-test-id={`selected-user-${i}-name`}
                >
                  {field.value}
                </Typography>
                <Controller
                  name={`selectedUsers.${i}.role`}
                  control={control}
                  defaultValue={field.role || UserOptions.MEMBER}
                  rules={{ required: "This field is required" }}
                  render={({ field: { value, onChange } }) => (
                    <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                      <Select
                        id={`selectedUsers.${i}.role.field`}
                        placeholder={"Role"}
                        value={value || ""}
                        onChange={(e) => {
                          onChange(e.target.value);
                          if (e.target.value === UserOptions.REMOVE) {
                            setHiddenMembers((prev) => [...prev, field.user_id]);
                          }
                        }}
                        error={fieldError && true}
                        IconComponent={CaretDown}
                        input={
                          <InputBase
                            sx={{
                              border: "none",
                              fontSize: "0.875rem",
                              "& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input": { paddingRight: "1.5rem" },
                            }}
                          />
                        }
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 200,
                            },
                          },
                        }}
                        e2e-test-id={`selected-user-${i}-role-select`}
                      >
                        {teamRoleOptions.map(({ id, name: optionName }) => (
                          <StyledMenuItem value={id} key={id} e2e-test-id={`selected-user-${i}-role-${optionName}`}>
                            {optionName}
                          </StyledMenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                />
              </Stack>
              {i < fields.length - 1 && <Divider />}
            </Box>
          );
        })}
      </Stack>
      {standAlone && fields.length > 0 && (
        <>
          <Divider />
          <ButtonStack flexDirection={"row"}>
            <Button label="Cancel" variantType="tertiary" onClick={handleClear} />
            <Button label="Done" variantType="primary" type="submit" />
          </ButtonStack>
        </>
      )}
    </>
  );
};
