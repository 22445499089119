import { Button } from "@alterdomus-analytics/dna-ui";
import {
  Autocomplete,
  MenuItem,
  Stack,
  styled,
  Typography,
  Tooltip,
  IconButton,
  Box,
  FormControlLabel,
  Chip,
  Snackbar,
} from "@mui/material";

export const ListItemPartStyled = styled("span")(() => ({
  fontWeight: 400,
}));

// EditDealTeam
export const DealTeamSearch = styled(Autocomplete)(() => ({
  width: "calc(100% - 0.5rem)",
  marginRight: "0.5rem",

  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
    paddingTop: "7px",
    paddingLeft: "10px",
    paddingBottom: "6px",
    paddingRight: "16px",
  },
  "& > div > div > fieldset": {
    borderColor: "#b4b7b7",
  },
  "& > div > div > input": {
    fontSize: "14px",
  },
}));

export const SearchItem = styled(Box)`
  & .MuiTypography-root.MuiTypography-body2 {
    font-size: 0.75rem;
    font-weight: 400;
    color: #586463;
    padding-left: 1.7rem;
  }
`;

interface ListItemPartStyledProps {
  highlight: boolean;
}
export const ItemPartStyled = styled("span")<ListItemPartStyledProps>(({ highlight }) => ({
  fontWeight: highlight ? 700 : 400,
  fontSize: "0.875rem",
  color: "#313636",
}));

export const SearchMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: theme.spacing(0.75, 2),
  whiteSpace: "normal",
  textTransform: "inherit",
  width: "100%",
  minHeight: "initial",
  lineHeight: theme.typography.pxToRem(20),
  color: "#313636",
  textAlign: "left",
  display: "flex",
  gap: theme.spacing(1),
}));

export const StyledChip = styled(Chip)(({ theme }) => ({
  fontSize: theme.spacing(1.5),
  fontWeight: 500,
  borderRadius: theme.spacing(1.75),
  paddingLeft: theme.spacing(1.5),
  paddingRight: theme.spacing(1.5),
  marginTop: 0,
  marginBottom: 0,
  backgroundColor: "#E4E8F2",
  height: 28,
  border: "2px solid transparent",

  "&.MuiChip-deletable": {
    paddingRight: 0,
    marginTop: 0,
    marginBottom: 0,
  },

  "& .MuiChip-deleteIcon": {
    padding: theme.spacing(1),
    margin: 0,
    width: 28,
    height: 28,
    fill: "#292727",
    borderRadius: 28,
    marginRight: -2,
  },

  "& .MuiChip-label": {
    color: "#292727",
    padding: 0,
  },

  "&:hover": {
    backgroundColor: "#c9d3ea",

    "& .MuiChip-deleteIcon": {
      color: "#292727",
    },
  },

  "&:focus-visible": {
    borderColor: theme.palette.text.primary,
  },
}));

export const StyledFormControlLabel = styled(FormControlLabel)`
  & .MuiFormControlLabel-label {
    font-size: 0.875rem;
  }
  & > span {
    padding-right: 0.5rem;
    padding-top: 0.325rem;
    padding-bottom: 0.325rem;
  }
  & > span > input {
    padding: 0;
  }
`;
export const InfoText = styled(Typography)`
  font-size: 1rem;
  color: #586463;
  margin-right: 0.5rem;
`;

export const StyledTooltip = styled(Tooltip)``;

export const StyledIconButton = styled(IconButton)`
  padding: 0px;
`;

export const StyledSecondaryButton = styled(Button)`
  height: 2rem;
  padding: 0.5rem 1rem;
  color: #1a50dd;
  font-size: 0.75rem;
  border-radius: 100px;
  border: 1px solid rgba(26, 80, 221, 0.6);
  background-color: #fff;
  text-transform: none;
`;
export const StyledMenuItem = styled(MenuItem)({
  fontFamily: "Inter, sans-serif",
  fontSize: "14px",
  fontWeight: 400,
  lineHeight: "20px",
  paddingTop: "4px",
  paddingBottom: "4px",
  "&.Mui-selected": {
    fontWeight: 600,
    backgroundColor: "#E8EAEE",
  },
});

export const ButtonStack = styled(Stack)`
  justify-content: flex-end;
  margin-top: 1rem;
  .MuiButtonBase-root.MuiButton-root {
    color: #1a50dd;
    :first-of-type {
      margin-right: 0.5rem;
    }
  }
  .MuiButton-root.MuiButton-contained {
    color: #fff;
    background-color: #1a50dd;
  }
`;

export const ViewTeamContainer = styled(Stack)`
  height: 100%;
  .MuiTypography-body1 {
    color: #586463;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      color: #292727;
      margin-left: 0.25rem;
    }
  }
`;

type AlertColor = "success" | "info" | "warning" | "error";

export const StyledSnackbar = styled(Snackbar)<{ appearance: AlertColor }>(({ theme, appearance }) => {
  const colorStyleMap = {
    info: {
      backgroundColor: "#D9E0F5",
      borderColor: "#1A50DD",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#1A50DD",
      },
    },
    error: {
      backgroundColor: "#FCE4E3",
      borderColor: "#E72215",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#E72215",
      },
    },
    warning: {
      backgroundColor: "#FBE7C6",
      borderColor: "#E1802B",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#E1802B",
      },
    },
    success: {
      backgroundColor: "#D2E9E7",
      borderColor: "#019886",

      "& .MuiAlert-icon .Dui-Icon": {
        fill: "#019886",
      },
    },
  };
  return {
    "& .MuiPaper-root.MuiAlert-root ": {
      border: 0,
      borderLeft: 3,
      borderStyle: "solid",
      color: "#292727",
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(20),
      fontWeight: 400,
      padding: theme.spacing(2, 1.625),
      paddingRight: theme.spacing(2.375),
      display: "flex",
      width: "100%",
      gap: theme.spacing(1),
      ...colorStyleMap[appearance],

      "& .MuiAlert-icon ": {
        padding: 0,
        marginRight: 0,
        alignItems: "center",
      },

      "& .MuiAlert-message ": {
        padding: 0,
        width: "100%",
        marginTop: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      },

      "& .MuiAlert-message .MuiLink-root ": {
        marginTop: theme.spacing(1),
      },

      "& .MuiAlert-action ": {
        padding: 0,
      },
    },

    width: "100%",
  };
});

export const LoaderContainer = styled(Box)`
  height: 100%;
  padding-top: 2rem;
`;
