import { FileRejection } from "../pages/UploadPage/types";
import { ReportLineItem } from "../types";

export const getFiles = (data: ReportLineItem[], index: number) => {
  const files: Map<string, string> = new Map();

  for (const item of data) {
    if (!item.values) continue;

    if (!item.values[index]?.file_upload_uri) {
      continue;
    }
    files.set(item.values[index].file_upload_name as string, item.values[index].file_upload_uri as string);
  }

  return Array.from(files.entries());
};

export const validateFiles = (acceptedFiles: File[], fileRejections: FileRejection[], fileSizeLimit: number) => {
  const messages = {
    required: "Upload of a file is required",
    lessThan20MB: "This is larger than the 20MB limit",
    acceptedTypes: "This file type is not accepted",
  };
  // Check for file rejections and handle specific errors
  if (fileRejections.length > 0) {
    const fileError = fileRejections[0]?.errors;
    const newFileRejectionsCode = fileRejections.map(({ errors }) => errors.map((e) => e.code));

    if (newFileRejectionsCode.some((codes) => codes.includes("file-invalid-type"))) {
      return { errorMessage: messages.acceptedTypes };
    } else if (newFileRejectionsCode.some((codes) => codes.includes("file-too-large"))) {
      return { errorMessage: messages.lessThan20MB };
    } else {
      return { errorMessage: fileError[0]?.message || messages.required };
    }
  }

  // Check if no files were accepted
  if (acceptedFiles.length === 0) {
    return { errorMessage: messages.required };
  }

  // Check file sizes before adding
  const oversizedFiles = acceptedFiles.filter((file) => file.size >= fileSizeLimit);
  if (oversizedFiles.length > 0) {
    return { errorMessage: messages.lessThan20MB };
  }

  return { fileData: acceptedFiles };
};
