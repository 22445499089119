import { Route, Routes } from "react-router-dom";
import { AnalyzerPage } from "../pages/AnalyzerPage";
import { CompanyPage } from "../pages/CompanyPage";
import { ErrorPage } from "../pages/ErrorPage";
import { InternalUnauthorizedPage } from "../pages/InternalUnauthorizedPage";
import { LoginPage } from "../pages/LoginPage";
import { ReportedViewPopup } from "../pages/ReportedViewPopup";
import { PdfViewer } from "../components/StatementTable/PdfViewer";
import { SessionTimeoutPage } from "../pages/SessionTimeoutPage";
import { authGuard } from "../services/auth/guard";
import { idleGuard } from "../services/auth/idleGuard";
import { UploadPage } from "../pages/UploadPage";
import { NewDealPage } from "../pages/NewDealPage";
//import {GridCellBalancePage} from "../pages/GridCell";

export const RoutesComponent = () => {
  return (
    <Routes>
      <Route path="*" Component={LoginPage} caseSensitive={false}></Route>
      <Route path="/analyzer" Component={idleGuard(authGuard(AnalyzerPage))} caseSensitive={false}></Route>
      <Route path="/company/:companyId" Component={idleGuard(authGuard(CompanyPage))} caseSensitive={false}></Route>
      <Route
        path="/view_reported/:companyId/:hash"
        Component={idleGuard(authGuard(ReportedViewPopup))}
        caseSensitive={false}
      ></Route>
      <Route path="/pdf_viewer/:companyId/:hash" Component={idleGuard(authGuard(PdfViewer))} caseSensitive={false}></Route>
      <Route path="/upload" Component={idleGuard(authGuard(UploadPage))} caseSensitive={false}></Route>
      <Route path="/new-deal" Component={idleGuard(authGuard(NewDealPage))} caseSensitive={false}></Route>
      <Route path="/session-timeout" Component={SessionTimeoutPage} caseSensitive={false}></Route>
      <Route path="/error" Component={ErrorPage} caseSensitive={false}></Route>
      <Route path="/internal-unauthorized" Component={InternalUnauthorizedPage} caseSensitive={false}></Route>
    </Routes>
  );
};
