import { Icon } from "@alterdomus-analytics/dna-ui";
import { Alert, AlertColor, Stack, Typography } from "@mui/material";
import { CheckCircle, Info, ProhibitInset, WarningCircle } from "@phosphor-icons/react";
import { useEffect, useState } from "react";
import { ArrayPath, UseFieldArrayProps, useFieldArray, useForm, useFormContext } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";
import { setCompanyField } from "../../redux/reducers/companyReducer";
import { updateDealTeam } from "../../services/deals";
import { DealTeamForm } from "./DealTeamForm";
import { InfoText, StyledIconButton, StyledSnackbar, StyledTooltip, ViewTeamContainer } from "./styles";
import { DealTeamMembers, DealTeamMembersForm, EditDealTeamProps, IOptionDealTeam, UserOptions, UserRole } from "./types";
import { getUser } from "../../services/users";

export const teamRoleOptions: UserRole[] = [
  { id: UserOptions.OWNER, name: "Deal Owner" },
  { id: UserOptions.MEMBER, name: "Team Member" },
  { id: UserOptions.REMOVE, name: "Remove from deal" },
];

export const EditDealTeam = ({
  companyId,
  orgId,
  dealTeam,
  close,
  reloadTeam,
  standAlone,
  name,
  dealTeamErrors = [],
  clearTeam = false,
}: UseFieldArrayProps<DealTeamMembersForm, ArrayPath<DealTeamMembersForm>> & EditDealTeamProps) => {
  const [openNotification, setOpenNotification] = useState<boolean>(true);
  const [formErrors, setFormErrors] = useState<string[] | undefined>([]);
  const [notificationAppearance, setNotificationAppearance] = useState<AlertColor>("info");
  const [initialMembers, setInitialMembers] = useState<DealTeamMembersForm | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const dispatch = useDispatch();
  const userState = useAppSelector((state) => state.app.user);
  const iconColorMap = { info: "#1A50DD", success: "#019886", warning: "#E1802B", error: "#E72215" };
  const iconMap = {
    info: Info,
    error: ProhibitInset,
    warning: WarningCircle,
    success: CheckCircle,
  };

  const { handleSubmit, reset, control, setValue } = useForm<DealTeamMembersForm>({ defaultValues: initialMembers });
  // formContext is used to handle form state for !standAlone case i.e. subform case
  const formContext = useFormContext<DealTeamMembersForm>();
  const { remove } = useFieldArray({
    control,
    name, // name for our Field Array is defined in parent component
  });
  const currentUser = [
    {
      label: userState.name ? userState.name : "",
      value: userState.name ? userState.name : "",
      user_id: userState.id ? userState.id : "",
      email: userState.email ? userState.email : "",
    },
  ];

  useEffect(() => {
    if (dealTeam && dealTeam.length > 0) {
      const userNames = dealTeam.map((member) => member.user_name);
      const fetchUserDetails = async () => {
        setIsLoading(true);
        try {
          const userDetailsArray = await Promise.all(
            userNames.map(async (userName) => {
              const result = await getUser(orgId, userName);
              return result.value ? result.value : [result];
            })
          );
          const flatUserDetailsArray = userDetailsArray.flat();

          const existingMembers: IOptionDealTeam[] = dealTeam.map((member) => {
            // Try to find an exact match by user_id because we had to make the query (to GraphAPI) by user_name
            const userDetails = flatUserDetailsArray.find((user) => user.id === member.user_id);

            return {
              label: member.user_name,
              value: member.user_name,
              user_id: member.user_id,
              email: userDetails ? userDetails.mail : "",
              role: member.is_owner ? UserOptions.OWNER : UserOptions.MEMBER,
            };
          });
          // Store all existing users in form state
          const existingMembersData: DealTeamMembersForm = { selectedUsers: existingMembers };
          setInitialMembers(existingMembersData);
          setValue(name, [...existingMembers]);
        } catch (error) {
          console.error("Error fetching user details:", error);
        }
        setIsLoading(false);
      };

      fetchUserDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitForm = async (data: any) => {
    const hasDealOwner = data.selectedUsers.some((m: DealTeamMembers) => m.role === UserOptions.OWNER);
    if (hasDealOwner) {
      const response = await updateDealTeam(companyId, data.selectedUsers);

      if (response?.success) {
        const dealTeamMembers = data.selectedUsers
          .filter((m: DealTeamMembers) => m.role !== UserOptions.REMOVE)
          .map((m: DealTeamMembers) => ({
            user_name: m.value,
            user_id: m.user_id,
            is_owner: m.role === UserOptions.OWNER,
          }));

        dispatch(setCompanyField({ deal_team: dealTeamMembers }));

        setFormErrors(["The Deal team has been updated."]);
        setNotificationAppearance("success");
        reset({});
        remove();
        if (close) close();
        if (reloadTeam) reloadTeam();
      } else {
        setFormErrors(response?.messages);
        setNotificationAppearance("warning");
      }
    } else {
      setNotificationAppearance("error");
      setFormErrors(["You need to add at least one deal owner. You can always change it later."]);
    }
  };

  function handleClear() {
    setFormErrors([]);
    reset({});
    remove();
    if (close) close();
  }

  useEffect(() => {
    if (dealTeamErrors && dealTeamErrors.length > 0) {
      setFormErrors(dealTeamErrors);
      setOpenNotification(true);
      setNotificationAppearance("error");
    }
  }, [dealTeamErrors]);

  const setFormErrorsNotification = (errors: string[]) => {
    setFormErrors(errors);
    if (errors.length > 0) {
      setOpenNotification(true);
    }
  };

  function handleCloseNotification() {
    setOpenNotification(false);
    setFormErrors([]);
  }

  return (
    <Stack>
      {formErrors && formErrors.length > 0 && (
        <>
          {formErrors.map((error, index) => (
            <StyledSnackbar
              key={index}
              open={openNotification}
              onClose={handleCloseNotification}
              sx={{ position: "static", marginBottom: "1rem", zIndex: 100 }}
              appearance={notificationAppearance}
            >
              <Alert
                onClose={handleCloseNotification}
                severity={notificationAppearance}
                variant="filled"
                icon={
                  <Icon component={iconMap[notificationAppearance]} color={iconColorMap[notificationAppearance]} weight="fill" />
                }
                sx={{ width: "100%" }}
              >
                {error}
              </Alert>
            </StyledSnackbar>
          ))}
        </>
      )}

      <Stack direction={"row"} mb={"1.5rem"} alignItems={"center"} justifyContent={standAlone ? "flex-start" : "center"}>
        <InfoText variant="body2">How does it work?</InfoText>
        <StyledTooltip
          title="Assign users who are already in the system to this deal. To invite new users to sign up, contact d&asupport@alterdomus.com. The deal owner will receive an email prompting them to build the deal profile."
          placement="right"
          leaveDelay={700}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                maxWidth: "270px",
                fontSize: "0.75rem",
                fontWeight: 400,
                backgroundColor: "#3C3C3B",
              },
            },
            arrow: {
              sx: {
                color: "#3C3C3B",
              },
            },
          }}
        >
          <StyledIconButton>
            <Info size={20} color="#1A50DD" weight="fill" />
          </StyledIconButton>
        </StyledTooltip>
      </Stack>

      {standAlone ? (
        <form onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
          <DealTeamForm
            control={control}
            name={name}
            orgId={orgId}
            currentUser={currentUser}
            standAlone={true}
            loading={isLoading}
            handleClear={handleClear}
            setErrors={setFormErrorsNotification}
            setAppearance={setNotificationAppearance}
          />
        </form>
      ) : (
        <DealTeamForm
          control={formContext.control}
          name={name}
          orgId={orgId}
          currentUser={currentUser}
          standAlone={false}
          loading={isLoading}
          handleClear={handleClear}
          setErrors={setFormErrorsNotification}
          setAppearance={setNotificationAppearance}
          clearCurrentUserChecked={clearTeam}
        />
      )}
    </Stack>
  );
};

interface ViewDealTeamProps {
  dealTeam: { user_name: string; is_owner: boolean }[];
  triggerReload: () => void;
  reload: boolean;
}

export const ViewDealTeam = ({ dealTeam, triggerReload }: ViewDealTeamProps) => {
  const [teamMembers, setTeamMembers] = useState<{ user_name: string; is_owner: boolean }[]>([]);
  const [reloadTeam, setReloadTeam] = useState(false);
  const storedDealTeam = useAppSelector((state) => state.app.selectedCompany.deal_team);
  const owners: string[] = [];
  const members: string[] = [];

  useEffect(() => {
    setReloadTeam(true);
    triggerReload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reloadTeam) {
      setTeamMembers(storedDealTeam);
    } else {
      setTeamMembers(dealTeam);
    }
  }, [reloadTeam, storedDealTeam, dealTeam]);

  teamMembers.forEach((member) => {
    if (member.is_owner) {
      owners.push(member.user_name);
    } else {
      members.push(member.user_name);
    }
  });

  return (
    <ViewTeamContainer direction="column">
      {owners.length > 0 && (
        <Typography variant="body1" component="p" mb={1}>
          Owners:
          <StyledTooltip
            title={owners.join(", ")}
            placement="bottom-start"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "13rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  backgroundColor: "#3C3C3B",
                },
              },
              arrow: {
                sx: {
                  color: "#3C3C3B",
                },
              },
            }}
          >
            <span>{owners.join(", ")}</span>
          </StyledTooltip>
        </Typography>
      )}
      {members.length > 0 && (
        <Typography variant="body1" component="p">
          Members:
          <StyledTooltip
            title={members.join(", ")}
            placement="bottom-start"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: "13rem",
                  fontSize: "0.875rem",
                  fontWeight: 400,
                  backgroundColor: "#3C3C3B",
                },
              },
              arrow: {
                sx: {
                  color: "#3C3C3B",
                },
              },
            }}
          >
            <span>{members.join(", ")}</span>
          </StyledTooltip>
        </Typography>
      )}
    </ViewTeamContainer>
  );
};
