import { Button, Header, LinearProgress, Snackbar } from "@alterdomus-analytics/dna-ui";
import { useEffect, useState } from "react";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { AppHeader } from "../../components/AppHeader";
import { SideBarMenu } from "../../components/SideBarMenu";
import { RootState } from "../../redux/store";
import { getAllDealNames } from "../../services/deals";
import { postFilesAndMetaData, UploadFormData } from "../../services/upload_documents";
import { LoaderContainer } from "../CompanyPage/styles";
import { ButtonStack, Container, FormStyled, HeaderContainer, StyledWrapper, TitleStyled, UploadContainer } from "./styles";
import { UploadForm } from "../../components/UploadForm";

export const UploadPage = () => {
  const [entityOptions, setEntityOptions] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [uploadMessage, setUploadMessage] = useState<string[]>([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [responseStatus, setResponseStatus] = useState(false);
  const [clear, setClear] = useState<boolean>(false);

  const storePortfolioId = useSelector<RootState, string>((state) => state.app.selectedPortfolio.data.id);

  const defaultData: UploadFormData | undefined = {
    entityName: null,
    files: [],
  };

  const methods = useForm<UploadFormData>({ defaultValues: defaultData });
  const { handleSubmit, control, reset } = methods;
  const { remove } = useFieldArray({
    control,
    name: "files", // unique name for our Field Array
  });

  // Get the entity name / companies  options
  useEffect(() => {
    setIsLoading(true);
    getAllDealNames(storePortfolioId).then((data) => {
      if (Array.isArray(data)) {
        setEntityOptions(data);
      } else {
        console.error("Error fetching deal names:", data.messages);
        setEntityOptions([]);
      }
      setIsLoading(false);
    });
  }, [storePortfolioId]);

  const submitForm = async (data: any) => {
    setUploading(true);
    const response = await postFilesAndMetaData(data);
    setUploadMessage(response.messages);
    if (response.success) setResponseStatus(true);

    setUploading(false);
    setClear(true);
  };

  useEffect(() => {
    if (responseStatus) {
      reset();
      remove();
    }
  }, [responseStatus, reset, remove]);

  useEffect(() => {
    if (uploadMessage.length > 0 && !uploading) {
      setSnackbarOpen(true);
    }
  }, [uploadMessage, uploading]);

  const handleClear = (data: any) => {
    reset();
    remove();
    setClear(true);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <AppHeader />
      <StyledWrapper>
        <SideBarMenu />
        <Container item xs={12}>
          <FormProvider {...methods}>
            <FormStyled onSubmit={handleSubmit(submitForm)} encType="multipart/form-data">
              <HeaderContainer isSticky={true}>
                <Header
                  title="Upload Documents"
                  width="100%"
                  children={
                    <ButtonStack flexDirection={"row"}>
                      <Button label="Cancel" variantType="tertiary" onClick={handleClear} />
                      <Button label="Submit" variantType="primary" type="submit" />
                    </ButtonStack>
                  }
                />
              </HeaderContainer>
              {isLoading && (
                <LoaderContainer>
                  <LinearProgress variant="query" />
                </LoaderContainer>
              )}
              <Snackbar
                open={snackbarOpen}
                message={uploadMessage[0]}
                severity={responseStatus ? "success" : "warning"}
                handleClose={handleClose}
              />
              <UploadContainer>
                {!uploading && <UploadForm entityOptions={entityOptions} clearForm={clear} />}
                {uploading && <TitleStyled variant="h4">Uploading...</TitleStyled>}
              </UploadContainer>
            </FormStyled>
          </FormProvider>
        </Container>
      </StyledWrapper>
    </>
  );
};
