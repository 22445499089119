import { Button, LinearProgress, Modal } from "@alterdomus-analytics/dna-ui";
import { CardHeader, Grid, Select, SelectChangeEvent, Stack, Typography } from "@mui/material";
import { CaretDown, DotOutline, Plus } from "@phosphor-icons/react";
import parse from "html-react-parser";
import { Base64 } from "js-base64";
import { Fragment, SetStateAction, useEffect, useRef, useState } from "react";
import { ModalActions } from "../../../../components/AnalystComment/styles";
import { Editor } from "../../../../components/Editor";
import { config } from "../../../../components/Editor/editor.config";
import { NotTableData } from "../../../../components/NotTableData";
import { useAppSelector } from "../../../../redux/hooks";
import {
  deleteAnalystById,
  getAllAnalystById,
  getAllAnalystByIdAndYear,
  getYearFilterOptions,
} from "../../../../services/analyst_comments";
import { AnalystComment, ManagementCommentary } from "../../../../types";
import { sanitizeText } from "../../../../utils/auxiliary";
import { formatDate } from "../../../../utils/formatDate";
import { AnalystCommentButton, CommentLoader, StyledButton, StyledSecondaryButton } from "../../styles";
import {
  AnalystCardActions,
  AnalystComments,
  BootstrapInput,
  ButtonStack,
  CommentCard,
  CommentContent,
  CommentCount,
  CommentText,
  EditorWrapper,
  PeriodButton,
  StyledMenuItem,
  TabTitle,
  Wrapper,
} from "./styles";
import { getAllManagementCommmentsByCompanyId, getAllManagementCommmentsByYear } from "../../../../services/management_comments";
import { Commentary } from "../../../../components/Commentary";
interface CommentaryTabProps {
  companyId: string;
  handleViewCommentaryClick: () => void;
  activeButton: Commentaries;
}

export enum Commentaries {
  Analyst = "analyst",
  Management = "management",
}

export const CommentaryTab = ({ companyId, handleViewCommentaryClick, activeButton }: CommentaryTabProps) => {
  const [activeSectionButton, setActiveSectionButton] = useState(activeButton);
  const [options, setOptions] = useState<number[] | undefined>([]);
  const [selectedYear, setSelectedYear] = useState<number | string>(options?.length ? options[0] : "Year");
  const [analystComments, setAnalystComments] = useState<AnalystComment[]>([]);
  const [managementComments, setManagementComments] = useState<ManagementCommentary[]>([]);
  const [commentAdded, setCommentAdded] = useState(false);
  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [isEditingCommentId, setIsEditingCommentId] = useState<number | null>(null);
  const [editingComment, setEditingComment] = useState<AnalystComment | undefined>(undefined);
  const [openModal, setOpenModal] = useState<number | null>(null);
  const [commentDeleteLoader, setCommentDeleteLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const userState = useAppSelector((state) => state.app.user);
  const isManagmentCommentTab = activeSectionButton === Commentaries.Management ? true : false;
  const [isManagementActiveTab, setIsManagementActiveTab] = useState(isManagmentCommentTab);

  const toggleModal = (commentId: number) => {
    setOpenModal((prevOpenModal) => (prevOpenModal === commentId ? null : commentId));
  };

  const handlePeriodButtonClick = (tab: SetStateAction<Commentaries>) => {
    if (tab === "management") {
      setIsManagementActiveTab(true);
    } else {
      setIsManagementActiveTab(false);
    }

    if (activeSectionButton !== tab) {
      setActiveSectionButton(tab);
    }
  };

  const handleEditComment = (id: number) => {
    const commentToEdit = analystComments.find((comment) => comment.id === id);
    setEditingComment(commentToEdit);
    setIsEditingCommentId(id); // Set isEditingCommentId to the ID of the comment being edited
    setOpenEditor(true);
  };

  const handleCloseEditor = () => {
    setEditingComment(undefined);
    setIsEditingCommentId(null);
    setOpenEditor((prev) => !prev);
  };

  const prevSelectedYear = useRef(selectedYear);

  const handleSelectChange = async (event: SelectChangeEvent<unknown>) => {
    setSelectedYear(event.target.value as number);

    try {
      if (selectedYear !== "Year") {
        if (activeSectionButton === Commentaries.Analyst) {
          const commentsForSelectedYear = await getAllAnalystByIdAndYear(+companyId!, event.target.value as number);
          setAnalystComments(commentsForSelectedYear);
          // Update the ref with the current selectedYear
          prevSelectedYear.current = selectedYear;
        } else if (activeSectionButton === Commentaries.Management) {
          const managementComments = await getAllManagementCommmentsByYear(+companyId!, event.target.value as number);
          setManagementComments(managementComments);
          prevSelectedYear.current = selectedYear;
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (activeSectionButton === Commentaries.Analyst && companyId) {
      setLoading(true);
      const fetchAnalystCommentData = async () => {
        try {
          const data = await getAllAnalystById(+companyId!);
          const years = await getYearFilterOptions(+companyId!);
          const comments = data.comments;
          if (data && years) {
            setAnalystComments(comments as AnalystComment[]);
            setOptions(years);
            setSelectedYear(data.latestYear);
          } else {
            setAnalystComments([]);
            setSelectedYear("Year");
            setOptions([]);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          // FIXME Handle the error appropriately e.g. show an error dialog
        } finally {
          setLoading(false);
        }
      };
      fetchAnalystCommentData();

      if (commentAdded) {
        // Fetch the updated data when a comment is added
        fetchAnalystCommentData();
        setCommentAdded(false);
      }
    }
  }, [companyId, commentAdded, activeSectionButton]);

  useEffect(() => {
    if (activeSectionButton === Commentaries.Management && companyId) {
      setLoading(true);
      const fetchManagementComments = async () => {
        try {
          const { availableYears, comments, latestYear } = await getAllManagementCommmentsByCompanyId(+companyId);
          if (comments) {
            setManagementComments(comments);
            setSelectedYear(latestYear || "Year");
            setOptions(availableYears);
          } else {
            setManagementComments([]);
            setSelectedYear("Year");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchManagementComments();
    }
  }, [activeSectionButton, companyId]);

  const handleDeleteComment = async (id: number) => {
    toggleModal(id);
    // Show the loader
    setCommentDeleteLoader(true);
    try {
      // Make the delete request
      const response = await deleteAnalystById(+companyId!, id);

      if (response) {
        // Fetch the updated data after a successful delete
        const data = await getAllAnalystById(+companyId!);
        const years = await getYearFilterOptions(+companyId!);
        const comments = data.comments;
        if (data && years) {
          setAnalystComments(comments as AnalystComment[]);
          setSelectedYear(data.latestYear);
          setOptions(years);
        } else {
          setAnalystComments([]);
          setSelectedYear("Year");
          setOptions([]);
        }
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    } finally {
      setCommentDeleteLoader(false);
    }
  };

  return (
    <Wrapper>
      <TabTitle variant="h3" e2e-test-id={`tab-title-analyst-commentary`}>
        {activeSectionButton === Commentaries.Analyst ? "Analyst Commentary" : "Management Commentary"}
      </TabTitle>

      <ButtonStack e2e-test-id={"commentary-buttons"}>
        <Stack display={"flex"} flexDirection={"row"}>
          <PeriodButton
            variant={activeSectionButton === Commentaries.Analyst ? "outlined" : "text"}
            onClick={() => handlePeriodButtonClick(Commentaries.Analyst)}
          >
            Analyst Commentary
          </PeriodButton>
          <PeriodButton
            variant={activeSectionButton === Commentaries.Management ? "outlined" : "text"}
            sx={{ marginLeft: ".5rem" }}
            onClick={() => handlePeriodButtonClick(Commentaries.Management)}
          >
            Management Commentary
          </PeriodButton>
        </Stack>

        <Stack display={"flex"} flexDirection={"row"} alignItems={"center"} columnGap={"1rem"}>
          {((analystComments?.length !== 0 && activeSectionButton === Commentaries.Analyst) ||
            (managementComments?.length !== 0 && activeSectionButton === Commentaries.Management)) && (
            <>
              <CommentCount>
                {activeSectionButton === Commentaries?.Analyst ? analystComments?.length : managementComments?.length} Comments
              </CommentCount>
              <Select
                value={selectedYear}
                onChange={handleSelectChange}
                input={<BootstrapInput />}
                placeholder="Year"
                IconComponent={CaretDown}
                MenuProps={{
                  variant: "menu",
                  sx: {
                    "& .MuiList-root": {
                      paddingTop: "8px",
                      paddingBottom: "8px",
                      "& .Mui-selected": {
                        backgroundColor: "#E8EAEE",
                        fontWeight: 600,
                      },
                    },
                  },
                }}
              >
                {options &&
                  options.map((item, key) => {
                    return (
                      <StyledMenuItem key={key} value={item}>
                        {item}
                      </StyledMenuItem>
                    );
                  })}
              </Select>
            </>
          )}
          {activeSectionButton === Commentaries.Analyst && (
            <StyledSecondaryButton
              label="Add Comment"
              variantType="secondary"
              startIcon={<Plus size={16} color="#1a50dd" />}
              onClick={() => setOpenEditor(true)}
            />
          )}
        </Stack>
      </ButtonStack>

      {activeSectionButton === Commentaries.Analyst ? (
        // Render the analystComments section
        <>
          {loading && <LinearProgress variant="query" />}
          {!loading && analystComments.length === 0 && !openEditor && (
            <NotTableData
              backgroundColor="#ffffff"
              border="1px solid #e2e2e2"
              title="Sorry, there's no comments to display here at the moment."
              content="Add the first commentary and share your analysis."
              button={
                <StyledButton
                  label="Add Comment"
                  variantType="primary"
                  startIcon={<Plus size={16} color="#ffffff" />}
                  onClick={() => setOpenEditor(true)}
                />
              }
            />
          )}
          {!isEditingCommentId && openEditor && (
            <EditorWrapper>
              <Editor
                companyId={companyId.toString()}
                config={config}
                user={userState.name}
                close={handleCloseEditor}
                onCommentAdded={() => {
                  setCommentAdded(true);
                }}
                editingComment={editingComment}
              />
            </EditorWrapper>
          )}
          {commentDeleteLoader && (
            <CommentLoader>
              <Stack>
                <Typography variant="caption">Deleting ...</Typography>
                <LinearProgress variant="query" />
              </Stack>
            </CommentLoader>
          )}
          {analystComments?.map((comment, index) => {
            const cId = comment.id;
            const text = comment.text;
            // Decode comment
            const decodedText = text ? Base64.decode(text) : "";
            const sanitizedText = sanitizeText(decodedText); // Sanitize text before parsing
            return (
              <Fragment key={cId}>
                {isEditingCommentId === cId && openEditor && (
                  <EditorWrapper key={cId}>
                    <Editor
                      companyId={companyId.toString()}
                      config={config}
                      user={userState.name}
                      close={handleCloseEditor}
                      onCommentAdded={() => {
                        setCommentAdded(true);
                      }}
                      editingComment={editingComment}
                    />
                  </EditorWrapper>
                )}

                {
                  // Conditionally render the AnalystComment only if it's not being edited
                  isEditingCommentId !== cId && (
                    <Fragment>
                      <AnalystComments>
                        <CommentCard variant="outlined" sx={{ maxWidth: "100%" }}>
                          <Grid container columnSpacing={"1.5rem"}>
                            <Grid item xs={12} sm={3}>
                              <CardHeader
                                title={
                                  <Stack>
                                    <Typography variant="h5" component="div" mr={1.5} e2e-test-id={`acomment-title-panel${cId}`}>
                                      {comment.user_name}
                                    </Typography>
                                    <Typography variant="body2" component="div" e2e-test-id={`acomment-subtitle-panel${cId}`}>
                                      {`Reporting Period ${Base64.decode(comment.reporting_period)}`}
                                    </Typography>
                                    <Typography variant="body2" component="div" e2e-test-id={`acomment-subtitle-panel${cId}`}>
                                      {formatDate(comment.timestamp)}
                                    </Typography>
                                  </Stack>
                                }
                              />
                            </Grid>
                            <CommentContent item xs={12} sm={9}>
                              <CommentText>
                                <Typography variant="body1" e2e-test-id={`acomment-content-panel${cId}`}>
                                  {parse(sanitizedText)}
                                </Typography>
                              </CommentText>
                              <AnalystCardActions>
                                <AnalystCommentButton
                                  type="button"
                                  label="Edit"
                                  variantType="tertiary"
                                  onClick={() => handleEditComment(cId)}
                                  e2e-test-id={`edit-button-acomment${cId}`}
                                />
                                <DotOutline size={16} color="#586463" weight="fill" />
                                <AnalystCommentButton
                                  type="button"
                                  label="Delete"
                                  variantType="tertiary"
                                  onClick={() => toggleModal(cId)}
                                  e2e-test-id={`delete-button-acomment${cId}`}
                                />
                              </AnalystCardActions>
                            </CommentContent>
                          </Grid>
                        </CommentCard>
                      </AnalystComments>
                      <Modal
                        open={openModal === cId}
                        onClose={() => toggleModal(cId)}
                        maxWidth={"sm"}
                        title="Delete this Comment?"
                        children="This will delete this comment permanently. You cannot undo this action"
                        footerSlot={
                          <ModalActions flexDirection={"row"} justifyContent={"flex-end"} gap={"1rem"} padding={"1rem 2rem"}>
                            <Button
                              type="button"
                              label="Cancel"
                              variantType="tertiary"
                              onClick={() => toggleModal(cId)}
                              e2e-test-id={`cancel-button-acomment-modal-${cId}`}
                            />
                            <Button
                              type="button"
                              label="Delete"
                              variantType="secondary"
                              onClick={() => handleDeleteComment(cId)}
                              e2e-test-id={`delete-button-acomment-modal-${cId}`}
                            />
                          </ModalActions>
                        }
                      />
                    </Fragment>
                  )
                }
              </Fragment>
            );
          })}
        </>
      ) : (
        // Render the Management Comments section
        <>
          {loading && <LinearProgress variant="query" />}
          {!loading && managementComments.length === 0 && (
            <NotTableData
              backgroundColor="#ffffff"
              border="1px solid #e2e2e2"
              title="Sorry, there's no comments to display here at the moment."
            />
          )}
          {managementComments &&
            managementComments?.map((comment, index) => {
              const cId = comment.id;
              // Decode comment, this will be usefull when we start editing the comments
              // const decodedText = text ? Base64.decode(text) : "";
              return (
                <Fragment key={cId}>
                  <Commentary
                    title="Management Commentary"
                    subtitle={comment.reporting_period}
                    date={formatDate(comment.timestamp)}
                    handleViewCommentaryClick={() => handleViewCommentaryClick()}
                    cutoff={260}
                    modalHeaderSlot
                    text={comment.text} // The  text is sanitize at the commentary component
                    maxWidth="100%"
                    activeTab={isManagementActiveTab}
                    styles={{ marginBottom: "1rem" }}
                    closeModal={true}
                    companyId={companyId!}
                  />
                </Fragment>
              );
            })}
        </>
      )}
    </Wrapper>
  );
};
